/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').component('book', {
        templateUrl: 'templates/book/book.html',
        controller: BookController,
        bindings: {
            listing: '<'
        }
    });

    BookController.$inject = [
        '$scope', '$rootScope', '$http', 'flashService', 'httpService', 'broadcastService',
        '$state', '$stateParams', '$anchorScroll', 'validationService', 'listingService', 'resortImages'
    ];

    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    function BookController($scope, $rootScope, $http, flashService, httpService, broadcastService,
        $state, $stateParams, $anchorScroll, validationService, listingService, resortImages) {

        var self = this;

        self.params = $stateParams;
        self.countries = {};
        self.states = {};
        self.listing = {};
        self.listingReady = false;

        //info passed on for the confirmation page
        self.confirmationInfo = {};
        self.luhn = function (value) {
            validationService.setValidity(self.bookingForm.payment, value);
        };

        // default booking form data
        self.bookingData = {
            listing: "",
            guest_first_name: "",
            guest_last_name: "",
            first_name: "",
            last_name: "",
            adults: 0,
            kids: 0,
            phone: "",
            email: "",
            street: "",
            street2: "",
            city: "",
            country_id: 237,
            state_id: "",
            zip: "",
            terms: false,
            insurance: false,
            credit_card_number: "",
            expiration_month: "",
            expiration_year: "",
        };

        // if in test mode merge in test defaults
        if ($rootScope.Debug) {
            self.bookingData = angular.extend(self.bookingData, {
                listing: "",
                guest_first_name: "First",
                guest_last_name: "Last",
                first_name: "First",
                last_name: "Last",
                adults: "1",
                kids: "0",
                phone: "1234567890",
                email: "email@email.com",
                street: "123 Fake Street",
                street2: "Apt 1",
                city: "City",
                country_id: 237,
                state_id: "3858",
                zip: "12345",
                terms: true,
                insurance: false,
                credit_card_number: "4111111111111111",
                expiration_month: "10",
                expiration_year: 2018,
            });
        }

        self.$onInit = onInit;
        self.hasPendingRequests = hasPendingRequests;
        self.ifNoStates = ifNoStates;
        self.exceedsOccupancy = exceedsOccupancy;
        self.insuranceCost = insuranceCost;
        self.totalCost = totalCost;
        self.firstPaymentCost = firstPaymentCost;
        self.bookSubmission = bookSubmission;
        self.showNoticeSection = showNoticeSection;
        self.country = country;
        self._country = null;
        self.resortImagesBaseUrl = resortImages.baseUrl

        //////////////////////////////////////////////////////////////////////////////////////////////////////

        function onInit() {
            var rental = null;
            if ($stateParams.rental) {
                rental = $stateParams.rental;
            }

            if ($stateParams.token) {
                listingService.fetchBDL($stateParams.listing, $stateParams.token).then(onReceiveListing);
            } else {
                listingService.fetchLead($stateParams.listing, rental).then(onReceiveListing);
            }

            $http.get($rootScope.Api('locations')).then(function (locations) {
                self.countries = locations.data;
                self._country = self.countries[0];
                self.states = self._country.states;
            });
        }

        function onReceiveListing(listing) {
            fbq('track', 'AddToCart', {
                'contents': [{'id': listing.id, 'quantity': 1, 'item_price': listing.price}],
                'content_type': 'product'
            });
            self.listing = listing;
            self.bookingData.listing = self.listing.id;
            self.listing.resort.resort_notices.forEach(function (notice) {
                notice.value = notice.value.replace(/\n/g, '<br />');
            });
            self.listingReady = true;
        }

        //loading icon
        function hasPendingRequests() {
            return httpService.hasPendingRequests();
        }

        function ifNoStates() {
            //if country is given and it's not the United States (the default)
            if (self.bookingData.country_id && self.bookingData.country_id !== 237 && !self.states[self.bookingData.country_id]) {
                self.bookingData.state_id = "XX";
            } else {
                self.bookingData.state_id = "";
            }
        }

        // @TODO Figure out how to make this work as a custom validator in angular
        // check if selected adults + children exceeds the listing's room occupancy
        function exceedsOccupancy() {
            if (self.listing && self.listing.room_type && !self.listing.room_type.occupancy) {
                return false;
            }
            if (self.listing && self.listing.room_type) {
                return parseInt(self.bookingData.adults, 10) + parseInt(self.bookingData.kids, 10) > parseInt(self.listing.room_type.occupancy, 10);
            }
        }

        function insuranceCost() {
            return self.listing && (self.listing.price * 0.0695);
        }

        function totalCost() {
            if (self.listing) {
                return parseFloat(self.listing.price) + parseFloat(self.insuranceCost());
            }
        }

        function firstPaymentCost(amount, isFirst) {
            if (!self.bookingData.insurance || !isFirst) {
                return amount;
            }
            return parseFloat(amount) + self.insuranceCost();
        }

        //SUBMISSIONS
        //submission of book final form
        function bookSubmission() {
            if (self.bookingForm.$invalid || self.exceedsOccupancy()) {
                $anchorScroll.yOffset = 20;
                $anchorScroll("formFailure");
                return;
            }

            var url;
            if (self.params.token) {
                url = $rootScope.Api('bdl', self.params.token);
            } else if (self.params.rental) {
                url = $rootScope.Api('ind', self.params.rental);
            } else {
                url = $rootScope.Api('book');
            }

            httpService.bookPost(self.bookingData, url).then(
                function successCallback (result) {
                    self.confirmationInfo = result.data;
                    broadcastService.updateConfirmationInfo(self.confirmationInfo);
                    $state.transitionTo("app.book-confirmation-token", {
                        token: self.confirmationInfo.confirmation_token,
                    });
                }, function errorCallback (result) {
                    flashService.error(result.data.message);
                }
            );
        }

        function showNoticeSection() {
            if (self.listing && self.listing.room_type) {
                return (self.listing.room_type.room_tax && (self.listing.room_type.room_tax > 0)) || self.listing.resort.resort_notices.length;
            }
            return false;
        }

        // Getter/setter for country
        function country(newCountry) {
            if (arguments.length) {
                self.states = newCountry.states;
                self.bookingData.country_id = newCountry.id;
                return self._country = newCountry;
            } else {
                return self._country;
            }
        }

        $scope.$watch('self.bookingData.country_id', function () {
            self.ifNoStates();
        });

        //watch and parse price to turn into number for expression addition
        $scope.$watch('self.listing.price', function () {
            if (self.listing) {
                self.listing.price = parseFloat(self.listing.price, 10);
            }
        });
    }
}());
