/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').component('slider', {
        controller: SliderController,
        templateUrl: 'templates/resorts/gmap/slider.component.html',
        bindings: {
            urlMap: '<',
            resortName: '<',
            isMobile: '<'
        }
    });

    SliderController.$inject = ['$scope', 'fancyboxService'];

    ////////////////////////////////////////////////////////////////////////////

    function SliderController($scope, fancyboxService) {
        var self = this;

        self.toggleMap = toggleMap;
        self.expandFancy = expandFancy;
        self.areaButton = $('.area');
        self.resortButton = $('.resort');
        self.isMap = true;
        self.expandMap = false;

        // expandGmapFancy
        // slugState
        // selectedIndex

        ////////////////////////////////////////////////////////////////////////

        function toggleMap(index) {
            var move = 0;
            if (index === 1) {
                move = -50;
            }
            $('div.map-elements').css('transform',"translateX(" + move + "%)");

            self.isMap = index === 0 ? true : false;

            if (self.isMap) {
                self.areaButton.addClass('active');
                self.resortButton.removeClass('active')
            } else {
                self.areaButton.removeClass('active');
                self.resortButton.addClass('active');
            }
        }

        function expandFancy() {
            if (self.isMap) {
                fancyboxService.getMap($scope);
            } else {
                fancyboxService.openMap(self.urlMap);
            }
        }
    }
}());
