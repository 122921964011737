resortModule.filter('today', ['dateFilter', function (dateFilter) {
    function filter(date, format, timezone) {
        // var today  = new Date().setHours(0,0,0,0);
        // var compare = new Date(date).setHours(0,0,0,0);

        // if (today === compare) {
        //     //change this return statement to return whatever text you want for today's date
        //     return date;
        // }
        return "on " + date;
    }
    return filter;
}]);
