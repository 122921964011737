/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').component('resorts', {
        controller: ResortsController,
        templateUrl: 'templates/resorts.component.html',
        bindings: {
            checkin: '<',
            checkout: '<',
            resortId: '<',
            isMobile: '<'
        }
    });

    ResortsController.$inject = ['$scope', '$state', '$location', 'resortService', 'listingService', 'http', 'mapService', 'scrollService', 'fancyboxService', 'resortImages'];

    ////////////////////////////////////////////////////////////////////////////

    function ResortsController($scope, $state, $location, resortService, listingService, http, mapService, scrollService, fancyboxService, resortImages) {
        var self = this;
        var reviewContainer = 'resort_reviews';

        self.resort = null;
        self.urlMap = null;
        self.lead = null;
        self.listings = [];
        self.relatedResorts = [];
        self.roomTypes = [];
        self.images = null;
        self.faqs = null;
        self.notices = null;
        self.reviews = null;
        self.interiorImages = null;
        self.exteriorImages = null;
        self.selectedIndex = null;
        self.resortReady = false;
        self.relatedResortsReady = false;
        self.resortImagesBaseUrl = resortImages.baseUrl

        self.$onInit = onInit;
        self.$onChanges = onChanges;
        self.hasRequests = hasRequests;
        self.expandMap = expandMap;
        self.mapReady = mapReady;
        self.listingsReady = listingsReady;
        self.reviewClick = reviewClick;

        ////////////////////////////////////////////////////////////////////////

        function onInit() {
            fetchResort(self.resortId).then(function (resort) {
                resetMapResort(resort);
                self.resortReady = true;
                fetchRelatedResorts(self.resort.destination_id).then(function (relatedResorts) {
                    resetMapRelatedResorts(relatedResorts);
                    self.relatedResortsReady = true;
                });
            });

            resortService.fetchRoomTypes(self.resortId).then(function (roomTypes) {
                self.roomTypes = roomTypes;
            });

            resortService.fetchImages(self.resortId).then(function (images) {
                self.images = images;
            });

            resortService.fetchFaqs(self.resortId).then(function (faqs) {
                self.faqs = faqs;
            });

            resortService.fetchNotices(self.resortId).then(function (notices) {
                self.notices = notices;
            });

            resortService.fetchReviews(self.resortId).then(function (reviews) {
                self.reviews = reviews;
            });

            listingService.fetchListings(self.resortId, self.checkin, self.checkout).then(function (listings) {
                self.listings = listings;

                if (!self.listings.sundance) {
                    var contents = [];
                    listings.forEach(function (listing) {
                        var listingObject = {
                            'id': listing.id,
                            'quantity': 1,
                            'price': listing.price
                        }

                        contents.push(listingObject)
                    })
                }

                fbq('track', 'ViewContent', {contents: contents, content_type: 'product'});
            });

            var queryParams = $location.search();
            if (queryParams.listing) {
                var rentalId = queryParams.rentalId ? queryParams.rentalId : null;
                listingService.fetchLead(queryParams.listing, rentalId).then(function (lead) {
                    self.lead = lead;
                });
            } else {
                self.lead = null;
            }
        }

        function onChanges(changes) {
            if (!changes.resortId || !changes.resortId.isFirstChange()) {
                self.dataReady = false;
                scrollService.scrollTop();
                fancyboxService.close();
                self.$onInit();
            }
        }

        function hasRequests() {
            return http.hasRequests();
        }

        function expandMap() {
            fancyboxService.getMap($scope);
        }

        function mapReady() {
            return self.resortReady && self.relatedResortsReady;
        }

        function listingsReady() {
            return self.resort && self.roomTypes && self.listings;
        }

        function reviewClick() {
            scrollService.scrollTo(reviewContainer);
        }

        ////////////////////////////////////////////////////////////////////////

        function fetchResort(resortId) {
            return resortService.fetchResort(resortId).then(function (resort) {
                self.resort = resort;
                self.urlMap = 'img/resorts/' + self.resortId + '/map/' + self.resort.resort_img;
                self.interiorImages = resort.interior_images;
                self.exteriorImages = resort.exterior_images;
                return self.resort;
            });
        }

        function fetchRelatedResorts(destinationId) {
            return resortService.fetchRelatedResorts(destinationId).then(function (relatedResorts) {
                self.relatedResorts = relatedResorts;
                return self.relatedResorts;
            });
        }

        function resetMapResort(resort) {
            mapService.setResortInfo(resort);
        }

        function resetMapRelatedResorts(relatedResorts) {
            mapService.setRelatedResorts(relatedResorts);
        }
    }
}());
