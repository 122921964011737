/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').component('home', {
        controller: HomeController,
        templateUrl: 'templates/home.html'
    });

    HomeController.$inject = ['$rootScope'];

    ////////////////////////////////////////////////////////////////////////////

    function HomeController($rootScope) {
        var self = this;

        self.images = [{
                url: '/images/home/banner/beach.jpg',
                name: 'VacationCandy Beach',
            },{
                url: '/images/home/banner/surf_boards.jpg',
                name: 'VacationCandy Surf Boards',
            },{
                url: '/images/home/banner/Waikiki_Hawaii.jpg',
                name: 'Waikiki Hawaii VacationCandy Resorts',
            },{
                url: '/images/home/banner/Atlantis.jpg',
                name: 'Atlantis Resort',
        }];

        self.openSearchBox = openSearchBox;

        ////////////////////////////////////////////////////////////////////////

        function openSearchBox() {
            $rootScope.$broadcast('openSearchBox');
        }
    }
}());
