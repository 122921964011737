/* global resortModule */
/* used on book page and confirmation page */
resortModule.controller('confirmController', ['$scope', '$rootScope', 'locaService', 'flashService', 'broadcastService', '$state', 'resortImages', function ($scope, $rootScope, locaService, flashService, broadcastService, $state, resortImages) {

    $scope.userBooking = $rootScope.Global.listing;
    $scope.confirmationInfo = locaService.confirmationInfo;
    $scope.resortImagesBaseUrl = resortImages.baseUrl;

    $scope.$on('confirmationInfoUpdated', function () {
        $scope.confirmationInfo = locaService.confirmationInfo;
    });

    //when confirmation page is loaded, this function initializes to get confirmation info from service
    $scope.initConfirmation = function () {
        if (!$scope.confirmationInfo.reservation && $rootScope.HasGlobal() && $rootScope.isNotEmpty($rootScope.Global.reservation)) {
            return broadcastService.updateConfirmationInfo($rootScope.Global);
        }

        if (!$scope.confirmationInfo) {
          flashService.error("Sorry! We were unable to locate your reservation. Please try logging in.");
          $state.transitionTo("app.book-confirmation");
        }
    };

    //parse totals for confirmation page
    $scope.parseFloat = function (value) {
        return parseFloat(value);
    };
}]);

/* GA REMOVED FROM PAGE

<!--$gaExtras = '

  _gaq.push(["_addTrans",
    "' . $confirmation_token . '",           // transaction ID - required
    "Vacation Candy",  // affiliation or store name
    "' . $total . '",          // total - required
    "",           // tax
    "",              // shipping
    "' . $contact["city"] . '",       // city
    "' . $contact["state"] . '",     // state or province
    "' . $contact["country"] . '"             // country
  ]);';

   // Reservation
$gaExtras = $gaExtras . '
  _gaq.push(["_addItem",
    "' . $confirmation_token . '",           // transaction ID - required
    "' . $reservation["original_rental_id"] . '",           // SKU/code - required
    "' . $reservation["roomtype"] . ' ' . date('m/d/Y', strtotime($reservation["start_date"])).' - '.date('m/d/Y', strtotime($reservation["end_date"])) . '",     // product name
    "' . $resort["resort_name"] . '",   // category or variation
    "' . $reservation["price"] . '",          // unit price - required
    "1"               // quantity - required
  ]);';

if (!empty($reservation["insurance_amount"])) {
    $gaExtras = $gaExtras . '
    _gaq.push(["_addItem",
      "' . $confirmation_token . '",           // transaction ID - required
      "insurance",           // SKU/code - required
      "Insurance",        // product name
      "Insurance",   // category or variation
      "' . $reservation["insurance_amount"] . '",          // unit price - required
      "1"               // quantity - required
    ]);';
}

//submits transaction to the Analytics servers
$gaExtras = $gaExtras . '_gaq.push(["_trackTrans"]);';-->

<!-- /ga -->

*/
