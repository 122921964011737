/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').component('googleMap', {
        templateUrl: 'templates/resorts/gmap/google-map.component.html',
        controller: GoogleMapController,
        bindings: {
            isLarge: '<',
            isMobile: '<',
            resortName: '<'
        }
    });

    GoogleMapController.$inject = ['mapService', 'scrollService'];

    ////////////////////////////////////////////////////////////////////////////

    function GoogleMapController(mapService, scrollService) {
        var self = this,
            mapIconPrimary = '/images/resorts/lollipin-orange-web.png',
            mapIconRelated = '/images/resorts/lollipin-web.png',
            mobileOffset = 0.01,
            smallOptions = {
                scrollwheel: false,
                zoomControl: false,
                draggable: true,
                navigationControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                disableDoubleClickZoom: false,
                keyboardShortcuts: true},
            largeOptions = {
                scrollwheel: true,
                zoomControl: true,
                draggable: true,
                navigationControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: true,
                disableDoubleClickZoom: false,
                keyboardShortcuts: true};

        self.map = null;
        self.window = null;
        self.marker = null;
        self.markers = [];

        self.$onInit = onInit;
        self.$onChanges = onChanges;
        self.scrollTop = scrollTop;

        function onInit() {
            var name = mapService.name,
                latitude = mapService.latitude,
                mapLatitude = latitude,
                longitude = mapService.longitude,
                destination = mapService.destination,
                relatedResorts = mapService.relatedResorts,
                slug = mapService.slug,
                options = smallOptions;

            if (self.isLarge === true) {
                options = largeOptions;
            }

            // Narrow/mobile screen options
            if (self.isMobile === true) {
                var lat = parseFloat(latitude),
                    sign = lat > 0 ? 1 : -1;

                options.draggable = false;
                options.mapTypeControl = false;
                mapLatitude = lat + (sign * mobileOffset);
            }

            self.map = makeMap(mapLatitude, longitude, name, options);
            self.marker = makeMarker(latitude, longitude, destination, name, slug);
            self.window = makeWindow(self.marker);
            self.markers = makeNearbyMarkers(relatedResorts);
            self.markers.push(self.marker);
        }

        function onChanges(changes) {
            if (!changes.resortName.isFirstChange()) {
                self.$onInit();
            }
        }

        function makeMap(lat, long, name, options) {
            return {
                center: {
                    latitude: lat,
                    longitude: long
                },
                zoom: 12,
                control: {},
                options: options,
                markersEvents: {
                    click: function(marker, eventName, model, args) {
                        if (self.isMobile === true) {
                            var lat = parseFloat(model.coords.latitude),
                                sign = lat > 0 ? 1 : -1;

                            self.map.center.latitude = lat + (sign * mobileOffset);
                        } else {
                            self.map.center.latitude = model.coords.latitude;
                        }

                        self.map.center.longitude = model.coords.longitude;
                        self.window = makeWindow(model);
                    }
                }
            };
        }

        function makeMarker(lat, long, destination, name, slug) {
            var marker = {
                id: 0,
                coords: {
                    latitude: lat,
                    longitude: long
                },
                options: {
                    icon: mapIconPrimary,
                    zIndex: 2,
                },
                title: name,
                dest: destination.id,
                slug: slug
            };

            return marker;
        }

        function makeNearbyMarkers(relatedResorts) {
            var markers = [];

            if (relatedResorts.length === 0) {
                return markers;
            }

            for (var i = 0, len = relatedResorts.length; i < len; i++) {
                markers.push({
                    id: relatedResorts[i].id,
                    dest: relatedResorts[i].destination_id,
                    coords: {
                        latitude: relatedResorts[i].latitude,
                        longitude: relatedResorts[i].longitude
                    },
                    options: {
                        icon: mapIconRelated,
                        zIndex: 1
                    },
                    title: relatedResorts[i].resort_name,
                    slug: relatedResorts[i].slug
                });
            }

            return markers;
        }

        function makeWindow(model) {
            return {
                show: true,
                options:{
                    pixelOffset: {
                        width: 0,
                        height: -70
                    }
                },
                model: model
            }
        }

        function scrollTop() {
            scrollService.scrollTop();
        }
    }
}());
