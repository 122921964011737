/*global angular*/
//app declaration
var resortModule = angular.module('resortApp', [
    'ngAnimate',
    'uiGmapgoogle-maps',
    'slickCarousel',
    'ui.router',
    'ngTouch',
    'ngRoute',
    'ngSanitize',
    'ui.bootstrap'
]);

// Constants

if (typeof google === 'undefined') {
    var google = null;
}

resortModule.constant('google', google);
