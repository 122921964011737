/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').factory('screen', Screen);

    ////////////////////////////////////////////////////////////////////////////

    function Screen() {

        var sizes = {
            xs: 0,
            sm: 1,
            md: 2,
            lg: 3,
            xl: 4
        };

        return {
            sizes: sizes,
            getSize: getSize
        };

        ////////////////////////////////////////////////////////////////////////

        function getSize(width) {
            // portrait phone
            if (width < 481) {
                return sizes.xs;
            }
            // landscape-ish phone
            if (width < 768) {
                return sizes.sm;
            }
            // portrait-ish tablet
            if (width < 992) {
                return sizes.md;
            }
            // landscape-ish tablet or small desktop
            if (width < 1023) {
                return sizes.lg;
            }
            // larger
            return sizes.xl;
        }
    }
}());
