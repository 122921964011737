/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').component('email', {
        controller: EmailController,
        templateUrl: 'templates/general/footer.html',
    });

    EmailController.$inject = [
        '$rootScope', '$http', 'scrollService', 'flashService'
    ];

    //////////////////////////////////////////////////////////////////////////////////////////////////

    function EmailController($rootScope, $http, scrollService, flashService) {
        var self = this;

        self.footerSubmit = false;
        self.loading = false;
        self.email = {
            text: ''
        };

        self.footerFormSubmit = footerFormSubmit;
        self.removeMessage = removeMessage;
        self.scrollTop = scrollTop;
        self.hasPendingRequests = hasPendingRequests;

        //////////////////////////////////////////////////////////////////////////////////////////////

        function footerFormSubmit() {
            self.loading = true;
            if (!self.email.text || self.email.text.length === 0 || !self.footerForm.$valid || self.footerForm.email.$error.email) {
                flashService.error("The email must be a valid email address");
                self.loading = false;
                return;
            }
            $http.post($rootScope.Api('newsletter'), {email: self.email.text})
            .then(
                function (result) {
                    self.loading = false;
                    if (result.data.created) {
                        fbq('track', 'Lead');
                        flashService.success();
                        self.email.text = '';
                    } else {
                        flashService.error(result.data.message);
                    }
                },
                function (result) {
                    flashService.error(result.data.message);
                }
            );
        }

        //used for dismiss link on flash message
        function removeMessage() {
            flashService.dismiss();
        }

        //scroll to the top of the page
        function scrollTop() {
            scrollService.scrollTop();
        }

        function hasPendingRequests() {
            return self.loading;
        }
    }
}());
