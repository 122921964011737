resortModule.directive("clickToClose",[ '$rootScope', function ($rootScope) {
    return {
        restrict: "A",
        link: function(scope, elem, attrs) {

            function check (e) {
                var container = $(elem);
                if (!container.is(e.target) && // if the target of the click isn't the container...
                    container.has(e.target).length === 0 // nor a descendant of the container
                ) {
                    scope.$apply($rootScope.$broadcast('closeSearchBox'));
                }
            }

            angular.element(document).on('mousedown', check);

            angular.element(window).bind('resize', function (e) {
                angular.element(document).off('mousedown', check);
                if (angular.element(document).find(elem).length > 0) {
                    angular.element(document).on('mousedown', check);
                }
            });
        }
    };
}]);
