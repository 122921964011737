//FACTORY declaration
resortModule.factory('logicService', ['locaService', 'httpService', 'broadcastService', 'rerouteService', 'findService', '$rootScope', function (locaService, httpService, broadcastService, rerouteService, findService, $rootScope) {

    //variables for factory
    var logicService = {};

    //updates resort and calls data functions
    logicService.newResort = function (resort) {
        //so it doesn't run multiple times
        broadcastService.updateResort(resort);
        logicService.getResortData(resort);
        logicService.getBookingData();
    };

    //change resort information and then redirect
    logicService.newResortInfo = function (resortInfo) {
        broadcastService.updateResortInfo(resortInfo);
        rerouteService.changeUrl();
    };

    //change start date information and then redirect
    logicService.newStartDate = function (startDate) {
         if (startDate !== locaService.startDate) {
            if (locaService.endDate && startDate > locaService.endDate) {
                broadcastService.updateEndDate(null);
            }
            broadcastService.updateStartDate(startDate);
            logicService.getBookingData();
            if (locaService.resort) {
                rerouteService.changeUrl();
            }
        }
    };

    //change end date information and then redirect
    logicService.newEndDate = function (endDate) {
        if (endDate !== locaService.endDate) {
            broadcastService.updateEndDate(endDate);
            logicService.getBookingData();
            if (locaService.resort) {
                rerouteService.changeUrl();
            }
        }
    };

    //change all post values
    logicService.newPost = function (resort, startDate, endDate) {
        broadcastService.updateStartDate(startDate);
        broadcastService.updateEndDate(endDate);
        broadcastService.updateResort(resort);
        logicService.getResortData(resort);
        logicService.getBookingData();
        if (locaService.resortInfo.slug) {
            rerouteService.changeUrl();
        }
    };

    //updates resort and grabs data for it
    logicService.newResortFromSlug = function (resortSlug) {
        function update () {
            logicService.getBookingData();
            rerouteService.changeUrl();
        }

        if ((locaService.resortInfo && locaService.resortInfo.slug && locaService.resortInfo.slug === resortSlug) ||
            (locaService.resortSlug && locaService.resortSlug === resortSlug)) {
            update();
            return;
        }
        httpService.getResortDataFromSlug(resortSlug).then(function (result) {
            broadcastService.updateResort(result.data.id);
            broadcastService.updateResortFromSlug(result.data);
            logicService.getRegionDestInfo(result.data.destination.region_id, result.data.destination.id);
            update();
        });
    };

    logicService.newPostFromSlug = function (resortSlug, startDate, endDate) {
        broadcastService.updateStartDate(startDate);
        broadcastService.updateEndDate(endDate);
        logicService.newResortFromSlug(resortSlug);
    };

    //assigns data retrieved from httpService
    logicService.getResortData = function (resort) {
        locaService.resort = resort;
        httpService.getResortData(resort).then(
            function (result) {
                logicService.newResortInfo(result.data);
            }
        );
    };

    // fetches listing data for when customer comes from an email or has listing id in url
    logicService.getListingData = function (listingId, rentalId) {
        locaService.featuredInfo = httpService.getListingData(listingId, rentalId).then(
            function (result) {
                var data;
                if (result.data) {
                    data = result.data;
                } else {
                    data = result;
                }
                return data;
            }
        );
        return locaService.featuredInfo;
    };

    //fetches resort information
    logicService.getBookingData = function () {
        var url;
        //two options for data post depending on what information has been given
        if (locaService.startDate && locaService.endDate) {
            url = $rootScope.Api('search', [locaService.resort, locaService.startDate, locaService.endDate]);
        } else  if (locaService.resort) {
            url = $rootScope.Api('searchDateless', locaService.resort);
        } else {
            url = null;
        }
        //if resort has been selected
        if (url) {
            url = String(url);
            httpService.getBookingData(url).then(
                function (result) {
                    broadcastService.updateBookInfo(result.data);
                }
            );
        }
    };

    //find and update region and destination information
    logicService.getRegionDestInfo = function (reg, dest) {
        var findArray = findService.findRegDest(reg, dest);
        region = findArray[0];
        destination = findArray[1];
        //update service with values
        broadcastService.updateRegion(region);
        broadcastService.updateRegionIndex(region.index);
        broadcastService.updateDestination(destination);
        broadcastService.updateDestinationName(destination.name);
        broadcastService.updateDestIndex(destination.index);
        logicService.getResortName(locaService.resort);
    };

    //find and update resort anme
    logicService.getResortName = function (resortId) {
        var resortName = findService.findResortName(resortId);
        broadcastService.updateResortName(resortName);
        return resortName;
    };

    //deciding what to reroute to from gooey link
    logicService.whereToLink = function (resort, listing) {
        //if resort information has not yet been called for
        if ( locaService.resortInfo.id !== resort) {
            logicService.newResort(resort);
        //if there is a resort and no listing in the url
        } else if (resort && !listing) {
            rerouteService.changeUrl();
        }
        //otherwise go home
        else {
            rerouteService.changeLinkHome();
        }
    };

    return logicService;
}]);
