resortModule.directive("halfStar", ['$timeout', function($timeout) {
    return {
        restrict: "A",
        link: function(scope, element, attrs) {
            if (scope.ratingDecimal) {
                var ratingUp = scope.ratingFloor + 1;
                var child = 'span:nth-child(' + ratingUp + ')';
                if ($(element).is(child)) {
                    $timeout(function() {
                        element.addClass('half-full');
                    });
                }
            }
        }
    };
}]);