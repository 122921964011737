/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').component('exteriorGallery', {
        controller: ExteriorGalleryController,
        bindings: {
            exteriorImages: '<',
            screenType: '@',
            resortImagesBaseUrl: '<'
        },
        templateUrl: ['$element', '$attrs', function ($element, $attrs) {
            return 'templates/resorts/gallery/outside/' + $attrs.screenType + '.html';
        }]
    });

    ExteriorGalleryController.$inject = [
        '$timeout', 'fancyboxService'
    ];

    ////////////////////////////////////////////////////////////////////////////////////

    function ExteriorGalleryController($timeout, fancyboxService) {
        var self = this;

        self.dataReady = null;

        self.$onChanges = onChanges;
        self.openFancyImages = openFancyImages;

        ////////////////////////////////////////////////////////////////////////////////

        //Toggle flag with timeout to trigger slick carousel re-init
        function onChanges(changes) {
            self.dataReady = false;
            $timeout(function() {
                self.dataReady = true;
            }, 10);
        }

        function openFancyImages(index) {
            fancyboxService.openGalleryImages(index, self.exteriorImages);
        }
    }
}());
