/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').factory('mapService', MapService);

    ////////////////////////////////////////////////

    function MapService () {

        var mapObject = {
            name: null,
            latitude: null,
            longitude: null,
            slug: null,
            destination: [],
            relatedResorts: [],
            setResortInfo: setResortInfo,
            setRelatedResorts: setRelatedResorts
        };

        return mapObject;

        ///////////////////////////////

        function setResortInfo(resort) {
            mapObject.name = resort.resort_name;
            mapObject.latitude = resort.latitude;
            mapObject.longitude = resort.longitude;
            mapObject.slug = resort.slug;
            mapObject.destination = resort.destination_id;
        }

        function setRelatedResorts(relatedResorts) {
            mapObject.relatedResorts = relatedResorts;
        }
    }
}());
