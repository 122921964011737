//opens template
resortModule.directive('fancybox', ['$compile', '$http', function ($compile, $http) {
    return {
        restrict: 'A',
        controller: ['$scope', function ($scope) {
            $scope.openFancybox = function (url) {
                $http.get(url).then(function (response) {
                    if (response.status === 200) {
                        var template = angular.element(response.data);
                        var compiledTemplate = $compile(template);
                        compiledTemplate($scope);
                        $.fancybox.open({
                            fitToView   : true,
                            content     : template,
                            type: 'html',
                            helpers: {
                                overlay: {
                                    locked: true
                                }
                            }
                        });
                    }
                });
            };
        }]
    };
}]);

//opens single image
resortModule.directive('fancyboxImg', function() {
    return {
        restrict: 'A',
        link: function(scope, element, attr) {
            $(element).fancybox({
                type        :'image',
                scrolling   : 'no',
                fitToView   : true,
                width       : '100%',
                closeClick  : true,
                openEffect  : 'none',
                closeEffect : 'none'
            });
        }
    };
});