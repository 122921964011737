/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').component('contactUs', {
        controller: ContactController,
        templateUrl: 'templates/contact-us.html',
    });

    ContactController.$inject = [
        '$rootScope', '$http', 'httpService', 'flashService'
    ];

    //////////////////////////////////////////////////////////////////////////////////////////////////

    function ContactController($rootScope, $http, httpService, flashService) {
        var self = this;

        self.contact = {
            name: '',
            email: '',
            phone: '',
            message: ''
        };

        self.contactSubmit = contactSubmit;
        self.hasPendingRequests = hasPendingRequests;

        //////////////////////////////////////////////////////////////////////////////////////////////

        function contactSubmit() {
            if (!self.contact.email ||
                self.contact.email.length === 0 ||
                !self.contact.name ||
                self.contact.name.length === 0 ||
                !self.contactForm.$valid
            ) {
                flashService.error();
                return;
            }

            $http.post($rootScope.Api('contactUs'), self.contact)
            .then(
                function (result) {
                    if (result.data.created) {
                        fbq('track', 'Lead');
                        flashService.success();
                        self.contact.email = "";
                        self.contact.name = "";
                        self.contact.message = "";
                        self.contact.phone = "";
                    } else {
                        flashService.error(result.data.message);
                    }
                },
                function (result) {
                    flashService.error(result.data.message);
                }
            );
        }

        function hasPendingRequests() {
            return httpService.hasPendingRequests();
        }
    }
}());
