/* **** for later use. commented out html already in home.html template
resortModule
.directive('vcParallax', ['$window',
    function ($window) {
        return {
            restrict: 'A',
            scope: {
                offset : '@',
                yOffset : '@',
            },
            link: function ($scope, element, attrs) {
                if ($window.innerWidth > 768) {
                    var oldWindowPos;
                    var Yset;
                    var Xset;
                    angular.element($window).bind("scroll", function() {
                        var currentWindowPosition = $window.pageYOffset;
                        var el = $(element).find('.slick-active');
                        var backgroundPos = el.css('backgroundPosition').split(" ");
                        var elementYOffset = parseFloat(backgroundPos[1]);
                        var elementXOffset = parseFloat(backgroundPos[0]);
                        var scaleEl = el.css('background-size').split(" ");
                        var scale = scaleEl[0];
                        if (oldWindowPos > currentWindowPosition) {
                            Yset = elementYOffset - parseFloat($scope.yOffset);
                            Xset = elementXOffset - parseFloat($scope.offset);
                            scale = scale + 0.1;
                            console.log(Xset);
                        } else {
                            Yset = elementYOffset + parseFloat($scope.yOffset);
                            Xset = elementXOffset + parseFloat($scope.offset);
                            scale = scale - 0.1;
                        }
                        oldWindowPos = currentWindowPosition;
                        el.css('background-position', Xset + '% ' + Yset + '%');
                        //el.css('background-size', Xset + '% ' + Yset + '%');
                    });
                }
            } // link function
        };
    }
]);*/