/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').component('sitemap', {
        controller: SitemapController,
        templateUrl: 'templates/sitemap.html',
    });

    SitemapController.$inject = [
        '$rootScope', '$http', '$state', 'locaService', 'logicService'
    ];

    //////////////////////////////////////////////////////////////////////////////////////////////////

    function SitemapController($rootScope, $http, $state, locaService, logicService) {
        var self = this;

        self.$onInit = onInit;
        self.resorts = [];
        self.resortClicked = resortClicked;

        //////////////////////////////////////////////////////////////////////////////////////////////

        function onInit() {
            var region, destination, resort;
            for (var i = 0; i < window.VCAppData.regions.length; i++) {
                region = window.VCAppData.regions[i]
                for (var j = 0; j < region.destinations.length; j++) {
                    destination = region.destinations[j];
                    for (var k = 0; k < destination.resorts.length; k++) {
                        resort = destination.resorts[k];
                        self.resorts.push(resort);
                    }
                }
            }

            self.resorts.sort(function (a, b) {
                if (a.resort_name < b.resort_name) {
                    return -1;
                } else if (a.resort_name > b.resort_name) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }

        function resortClicked(resortSlug) {
            $state.go('app.resorts.slug', {slug: resortSlug});
        }
    }
}());
