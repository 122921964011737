//FACTORY declaration
resortModule.factory('paramService', ['locaService', 'logicService', 'broadcastService', '$timeout', 'scrollService', function (locaService, logicService, broadcastService, $timeout, scrollService) {

    //variables for factory
    var paramService = {};

    paramService.updateResort = function (resort) {
        logicService.newResort(resort.id);
        logicService.getRegionDestInfo(resort.destination.region_id, resort.destination_id);
    };

    paramService.updateAll = function (resort, startDate, endDate) {
        logicService.newPost(resort.id, startDate, endDate);
        logicService.getRegionDestInfo(resort.destination.region_id, resort.destination_id);
    };

    paramService.updateResortFromSlug = function (slug, startDate, endDate) {
        if (startDate && endDate) {
            logicService.newPostFromSlug(slug, startDate, endDate);
        } else {
            logicService.newResortFromSlug(slug);
        }
    };

    paramService.updateFeaturedListing = function (listing) {
        broadcastService.updateFeaturedInfo(listing);
    };

    paramService.reloadRoutes = function (params, global) {
        // Check for slug
        if (params.slug) {
            if (global.resort && !locaService.resort) {
                if (params.startDate && params.endDate) {
                    paramService.updateAll(global.resort, params.startDate, params.endDate);
                } else {
                    paramService.updateResort(global.resort);
                }
            } else {
                paramService.updateResortFromSlug(params.slug, params.startDate, params.endDate);
            }
        }

        // Check for ind-listing
        if (params.listingId) {
            if (global.listing && global.resort) {
                paramService.updateFeaturedListing(global.listing);
                paramService.updateAll(global.resort, global.listing.start_date, global.listing.end_date);
            } else {
                //get featuredInfo and pass to resort content controller through service
                logicService.getListingData(params.listingId, params.rentalId).then( function (result) {
                    if (result) {
                        paramService.updateFeaturedListing(result);
                        paramService.updateAll(result.resort, result.start_date, result.end_date);
                    }
                });
            }
        }
    };

    //UNIT
    paramService.clickUnit = function (fragment) {
        $("." + fragment + " .panel-title a").click();
    };

    paramService.decode = function (fragment) {
        return (decodeURIComponent(fragment).replace(/[\s]/g, ''));
    }

    //scroll down to and open bedroom type
    paramService.respondToUnit = function (fragment, resortInfo) {
        fragment = paramService.decode(fragment);
        if (fragment && resortInfo && resortInfo.room_types) {
            var roomTypesLength = resortInfo.room_types.length;
            for (var i = 0; i < roomTypesLength; i++) {
                var bedroom_type = resortInfo.room_types[i].bedroom_type.name.replace(/[\s]/g, '');
                if (fragment === bedroom_type) {
                    //set selected index, which is used for booking content filter
                    var selectedIndex = i;
                    //DOM response
                    $timeout(function () {
                        paramService.clickUnit(fragment);
                        scrollService.scrollTo(fragment);
                    }, 100, true);

                    if (selectedIndex) {
                        return selectedIndex
                    }
                    return 0;
                }
            }
        }
    };

    paramService.updateValuesToParams = function (params, service) {
        if (service.resortInfo.slug && params.slug && params.slug !== service.resortInfo.slug ||
            service.startDate && params.startDate && params.startDate !== service.startDate ||
            service.endDate && params.endDate && params.endDate !== service.endDate) {
            //grabs info
            paramService.reloadRoutes(params, {});
        }
    };

    return paramService;
}]);
