/*global resortModule*/
resortModule.controller('paymentController', ['$scope', 'locaService', 'flashService', 'httpService', 'broadcastService', '$rootScope', function ($scope, locaService, flashService, httpService, broadcastService, $rootScope) {

    function bookPaymentInfo (same_contact) {
        return {
            same_contact_info: same_contact,
            reservation_id: "",
            first_name: "",
            last_name: "",
            street: "",
            street2: "",
            city: "",
            country_id: "",
            state_id: "",
            zip: "",
            phone: "",
            credit_card_number: "",
            expiration_month: "",
            expiration_year: "",
            amount: ""
        };
    }

    //payment object to be submitted
    $scope.bookPayment = bookPaymentInfo(false);


    //validation
    $scope.terms = true;
    //dropdowns
    $scope.months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    $scope.countries = {};
    $scope.states = {};
    //form
    $scope.formSubmit = false;
    $scope.confirmationInfo = locaService.confirmationInfo;

    $scope.getInsuranceValue = function (reservation) {
        if (reservation && reservation.insurance !== "0") {
            return parseFloat(reservation.insurance_amount);
        }
        return false;
    };

    function getPriceValue (reservation) {
        if (reservation) {
            return parseFloat(reservation.price);
        }
        return false;
    }

    //calculated variables
    $scope.insurance = $scope.getInsuranceValue($scope.confirmationInfo.reservation);
    $scope.price = getPriceValue($scope.confirmationInfo.reservation);

    function setReservationId () {
        if (!$scope.bookPayment.reservation_id && $scope.confirmationInfo.reservation) {
            //set reservation id by app confirmation info if available
            $scope.bookPayment.reservation_id = $scope.confirmationInfo.reservation.reservation_id;
        }  else if (!$scope.bookPayment.reservation_id && $rootScope.isNotEmpty($rootScope.Global.reservation)) {
            //else look for global info
            $scope.bookPayment.reservation_id = $rootScope.Global.reservation.reservation_id;
        }
    }

    function setInvoiceAmount () {
        if ($scope.confirmationInfo.invoices.length) {
            $scope.bookPayment.amount = $scope.confirmationInfo.invoices[0].due_sum;
        } else {
            $scope.bookPayment.amount = "";
        }
    }

    //if address is available, set same contact equal to true
    function getAddress () {
        if ($scope.confirmationInfo.reservation && $scope.confirmationInfo.reservation.address) {
            $scope.bookPayment.same_contact_info = true;
        }
    }
    getAddress();

    function getDropdownLocations () {
        httpService.getLocationInfo()
            .then(function (result) {
                $scope.countries = result.data.countries;
                $scope.states = result.data.states;
            }
        );
    }

    function submitPayment () {
        httpService.paymentSubmission($scope.bookPayment, $rootScope.Api('payment'))
            .then(function successCallback (result) {
                if (result.data.data) {
                    $scope.confirmationInfo = result.data.data;
                    $scope.bookPayment = bookPaymentInfo(true);
                    setInvoiceAmount();
                    $scope.formSubmit = false;
                    flashService.success();
                } else {
                    $scope.formSubmit = true;
                    flashService.error(result.data.message);
                }
            }, function errorCallback (result) {
                $scope.formSubmit = true;
                flashService.error(result.data.message);
            });
    }

    //calculate reservation total
    $scope.reservationTotal = function() {
        return parseFloat($scope.confirmationInfo.reservation.price) + $scope.getInsuranceValue($scope.confirmationInfo.reservation);
    };

    //loading icon
    $scope.hasPendingRequests = function () {
        return httpService.hasPendingRequests();
    };

    //when page is loaded, this function initializes to get info from service
    $scope.updateConfirmInfo = function () {
        //service confirmation info
        if ($rootScope.isNotEmpty(locaService.confirmationInfo)) {
            return broadcastService.updateConfirmationInfo(locaService.confirmationInfo);
        }
        //global object confirmaiton info
        if ($rootScope.HasGlobal() && $rootScope.isNotEmpty($rootScope.Global.reservation)) {
            return broadcastService.updateConfirmationInfo($rootScope.Global);
        }
        //error message
        flashService.error("Sorry! We were unable to locate your reservation.");
    };

    //set test values so you don't have to fill out form
    if ($rootScope.Debug) {
        $scope.bookPayment = angular.extend($scope.bookPayment, {
            first_name: "First-Name",
            last_name: "Last-Name",
            phone: "1234567890",
            credit_card_number: "4111111111111111",
            expiration_month: "10",
            expiration_year: 2017,
        });
    }

    $scope.$on('confirmationInfoUpdated', function () {
        $scope.confirmationInfo = locaService.confirmationInfo;
        $scope.insurance = $scope.getInsuranceValue($scope.confirmationInfo.reservation);
        $scope.price = getPriceValue($scope.confirmationInfo.reservation);
        getAddress();
        getDropdownLocations();
        setInvoiceAmount();
        //set reservation id to match confirmation info
        $scope.bookPayment.reservation_id = $scope.confirmationInfo.reservation.reservation_id;
    });

    //SUBMISSIONS
    //submission of book final form
    $scope.paymentSubmission = function () {
        //validation
        if (!$scope.bookPayment.same_contact_info && $scope.addressForm.$invalid) {
            flashService.error("Please ensure your address information has been filled out.");
            $scope.formSubmit = true;
            return false;
        }
        if ($scope.paymentForm.$invalid) {
            flashService.error("Please ensure payment information has been filled out.");
            $scope.formSubmit = true;
            return false;
        }
        //get reservation id for submission
        setReservationId();
        //if terms have been accepted
        if ($scope.terms) {
            //submit to service
            submitPayment();
        } else {
            $scope.formSubmit = true;
            flashService.error("Please agree to our terms.");
        }
    };
}]);
