/*global angular, $*/
(function () {
    'use strict';

    angular.module('resortApp').component('searchBoxCalendar', {
        templateUrl: 'templates/search-box/search-box-calendar.component.html',
        controller: SearchBoxCalendar,
        bindings: {
            selectingCheckin: '<',
            selectingCheckout: '<',
            selectedCheckinValue: '<',
            selectedCheckoutValue: '<',
            checkin: '<',
            checkout: '<',
            isMobile: '<'
        }
    });

    SearchBoxCalendar.$inject = ['$scope'];

    ////////////////////////////////////////////////////////////////////////////

    function SearchBoxCalendar($scope) {
        var self = this;
        var initialized = false;

        self.numMonths = 3;
        self.checkoutPos = 0;
        self.checkinOffset = 0;
        self.checkinOffsetYear = 0;
        self.checkinCalendar = null;
        self.checkoutCalendar = null;

        self.$onInit = onInit;
        self.$onChanges = onChanges;

        ////////////////////////////////////////////////////////////////////////

        function onInit() {
            if (self.isMobile === true) {
                self.numMonths = [24, 1];
            }

            self.checkinCalendar = self.checkinCalendar || $('.ci', '.search-box-calendar');
            self.checkoutCalendar = self.checkoutCalendar || $('.co', '.search-box-calendar');
            initialized = true;
            self.$onChanges();
        }

        function onChanges(changes) {
            if (!initialized) {
                return;
            }

            if (self.selectingCheckin) {
                destroyCheckout();
                initCheckin();
            }

            if (self.selectingCheckout) {
                destroyCheckin();
                initCheckout();
            }
        }

        function initCheckin() {
            self.checkinCalendar.datepicker({
                defaultDate: self.checkin || null,
                minDate: 0,
                numberOfMonths: self.numMonths,
                showOtherMonths: true,
                nextText: " ",
                showCurrentAtPos: 0,
                beforeShowDay: checkinBeforeShowDay,
                onSelect: checkinOnSelect,
                onChangeMonthYear: checkinOnChangeMonthYear
            });

            self.checkinOffset = self.checkinCalendar.datepicker('getDate').getMonth() + 1;
            self.checkinOffsetYear = self.checkinCalendar.datepicker('getDate').getFullYear();
        }

        function destroyCheckin() {
            self.checkinCalendar.datepicker('destroy');
        }

        function initCheckout() {
            self.checkoutCalendar.datepicker({
                defaultDate: self.checkout || null,
                numberOfMonths: self.numMonths,
                minDate: self.checkin,
                nextText: ' ',
                showOtherMonths: true,
                gotoCurrent: true,
                showCurrentAtPos: getCheckoutPosition(),
                beforeShowDay: checkoutBeforeShowDay,
                onSelect: checkoutOnSelect
            });

            self.checkoutCalendar.on('mouseenter', function (event) {
                highlightDateRange(event);
            });
        }

        function destroyCheckout() {
            self.checkoutPos = self.checkoutCalendar.datepicker('option', 'showCurrentAtPos');
            self.checkoutCalendar.datepicker('destroy');
        }

        function checkinBeforeShowDay(date) {
            if (!self.checkin) {
                var today = new Date();
                today.setHours(0);
                today.setMinutes(0);
                today.setSeconds(0);
                if (Date.parse(date) === Date.parse(today)) {
                    return [true, 'today'];
                }
            }

            if (self.checkout) {
                var checkinDate = makeDate(self.checkin);
                var checkoutDate = makeDate(self.checkout);
                if (date.getTime() === checkoutDate.getTime()) {
                    return [true, 'highlight_end selected'];
                } else if (date.getTime() > checkinDate.getTime() && date.getTime() < checkoutDate.getTime()) {
                    return [true, 'highlight']
                }
            }

            return [true, '', ''];
        }

        function checkoutBeforeShowDay(date) {
            var checkinDate = makeDate(self.checkin);
            if (date.getTime() === checkinDate.getTime()) {
                return [true, 'highlight_begin selected'];
            }

            if (self.checkout) {
                var checkoutDate = makeDate(self.checkout);
                if (date.getTime() === checkoutDate.getTime()) {
                    return [true, 'highlight_end selected'];
                } else if (date.getTime() > checkinDate.getTime() && date.getTime() < checkoutDate.getTime()) {
                    return [true, 'highlight']
                }
            }

            return [true, '', ''];
        }

        function makeDate(value) {
            var date = new Date(value);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);

            return date;
        }

        function checkinOnSelect(date) {
            var checkin = new Date(date);
            var yearMod = 12 * (checkin.getFullYear() - self.checkinOffsetYear);
            self.checkoutPos = ((checkin.getMonth() + yearMod + 1) - self.checkinOffset);

            $scope.$apply(function () {
                self.selectedCheckinValue(checkin);
            });
        }

        function checkoutOnSelect(date) {
            $scope.$apply(function () {
                self.selectedCheckoutValue(new Date(date));
            });
        }

        function checkinOnChangeMonthYear(year, month, inst) {
            self.checkinOffsetYear = year;
            self.checkinOffset = month;
        }

        function getCheckoutPosition() {
            if (!self.checkin || !self.checkout) {
                return self.checkoutPos;
            }

            var ci = new Date(self.checkin);
            var co = new Date(self.checkout);
            var checkinMonth = ci.getMonth(),
                checkoutMonth = co.getMonth(),
                difference,
                position;

            //check to see which number is greater
            if (checkoutMonth >= checkinMonth) {
                difference = checkoutMonth - checkinMonth;
            } else {
                difference = (checkoutMonth + 12) - checkinMonth;
            }

            //test how great the difference is and set position
            if (difference >= 2) {
                position = 2;
            } else if (difference === 1) {
                position = 1;
            } else {
                position = 0;
            }

            return position;
        }

        function highlightDateRange(event) {
            $('td:not(.ui-datepicker-unselectable)').mouseenter(function (event) {

                //remove previously highlighted areas
                $('.highlight_end').removeClass('highlight_end');
                $('.finalBlock').removeClass('finalBlock');
                $('.finalRow').removeClass('finalRow');
                $('.endDate .ui-datepicker td:not(.highlight_begin)').removeClass('highlight');
                $('.highlightRow').removeClass('highlightRow');
                $('td:not(.highlight_begin)').removeClass('highlight');

                //add block class to month and highlight
                $(this).parents('.ui-datepicker-group').addClass('finalBlock');
                $('.finalBlock').prevAll().find('td:not(.ui-datepicker-unselectable):not(.ui-datepicker-current-day)').addClass('highlight');

                //add row class to week and highlight
                $(this).parent().addClass('finalRow');
                $('.finalRow').prevAll().find('td:not(.ui-datepicker-unselectable):not(.ui-datepicker-current-day)').addClass('highlight');

                $(this).prevAll('td:not(.ui-datepicker-unselectable):not(.ui-datepicker-current-day)').addClass('highlight');
                $(this).not('.ui-datepicker-current-day').addClass('highlight_end');
                $('.highlight').parent().addClass('highlightRow');
            });
        }
    }
}());
