/*global resortModule*/
resortModule.controller('loginController', ['$scope', '$rootScope', 'flashService', 'httpService', 'broadcastService', '$state', '$filter', 'validationService', function ($scope, $rootScope, flashService, httpService, broadcastService, $state, $filter, validationService) {

    //pre filtered dates
    $scope.ci = null;

    //payment object to be submitted
    $scope.reservationDetails = {
        reservation_id: "",
        email: "",
        start_date: "",
    };

    if ($rootScope.Debug) {
        var TestData = {
            reservation_id : "12345",
            email : "email@email.com",
            start_date : "2017-02-04",
        };
        $scope.reservationDetails = angular.extend($scope.reservationDetails, TestData);
        $scope.ci = '02/04/2017';
    }

    //info returned from post
    $scope.loginInfo = {};

    //loading icon
    $scope.hasPendingRequests = function () {
        return httpService.hasPendingRequests();
    };

    //watch
    //reformat dates
    $scope.$watch('ci', function () {
        $scope.reservationDetails.start_date = $filter('date')(new Date($scope.ci), "yyyy-MM-dd");
    });

    //SUBMISSIONS
    //submission of book final form
    $scope.loginSubmission = function () {
        var url, payment;

        //if it's a payment form
        if ($rootScope.ifPaymentForm()){
            //change post url
            url = $rootScope.Api('paymentLogin');
            payment = true;
        } else {
            //set post url to book confirmation
            url = $rootScope.Api('bookConfirmation');
            payment = false;
        }
        httpService.bookPost($scope.reservationDetails, url)
            .then(function successCallback (result) {
                // alert failure to locate reservation
                if (result.data.success) {
                    $scope.loginInfo = result.data.data;
                    //update confirmation info in service so confirmation page is filled out
                    broadcastService.updateConfirmationInfo($scope.loginInfo);
                    //redirect to confirmation page (same page as seen after booking)
                    //payment variable is set above from rootscope.ifpaymentform result
                    if (payment){
                        //if payment page, redirect here
                       $state.transitionTo("app.payment-token", {
                           token: $scope.loginInfo.reservation.confirmation_token
                        });
                    } else {
                        //or booking confirmation redirect
                        $state.transitionTo("app.book-confirmation-token", {
                            token: $scope.loginInfo.reservation.confirmation_token
                        });
                    }
                } else {
                    flashService.error(result.data.message);
                }
            }, function errorCallback (result) {
                flashService.error(result.data.message);
            });
    };
}]);
