resortModule.directive("fixedElement", ['$window', '$timeout', function ($window, $timeout) {
    return function(scope, element, attrs) {

        var top, bottom, calcBottom, elementHeight;
        var marginTop = 20;
        var comparison = $('.card-parent');

        function setVariables () {
            top = comparison.offset().top;
            bottom = comparison.height();
            elementHeight = $(element).height();
            calcBottom = bottom + top - elementHeight - (marginTop * 2);
        }
        function changeFixed () {
            var scrollTop = $(document).scrollTop();
            if (scrollTop >= (top - marginTop) && scrollTop < calcBottom) {
                $(element).removeClass('card-bottom');
                $(element).addClass('card-fixed');
            } else if (scrollTop >= calcBottom) {
                 $(element).removeClass('card-fixed');
                 $(element).addClass('card-bottom');
            } else {
                $(element).removeClass('card-fixed');
                $(element).removeClass('card-bottom');
            }
        }

        angular.element($window).bind("scroll", function() {
            setVariables();
            changeFixed();
            scope.$apply();
        });
    };
}]);
