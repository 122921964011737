//FACTORY declaration
resortModule.factory('httpService', ['$http', '$rootScope', function ($http, $rootScope) {

    var httpService = {};
    var resortInfo, featuredInfo, relatedResorts, bookInfo, resortRating, confirmationInfo, paymentInfo;

    //GET
    //fetches images and resort data
    httpService.getResortData = function (resort) {
        resortInfo = $http.get($rootScope.Api('resort', resort)).then(
            function (result) {
                return result;
            }
        );
        return resortInfo;
    };

    //get resort from url slug
    httpService.getResortDataFromSlug = function (resortSlug) {
        resortInfo = $http.get($rootScope.Api('resortSlug', resortSlug)).then(
            function (result) {
                return result;
            }
        );
        return resortInfo;
    };

    // fetches listing data for when customer comes from an email or has listing id in url
    httpService.getListingData = function (listingId, rentalId) {
        var url;
        if ($rootScope.isNotEmpty(rentalId)) {
            url = $rootScope.Api('listingInd', [listingId, rentalId]);
        } else {
            url = $rootScope.Api('listing', listingId);
        }
        featuredInfo = $http.get(url).then(
            function (result) {
                return result.data;
            }
        );
        return featuredInfo;
    };

    //fetches related resorts
    httpService.fetchRelatedResorts = function (resort) {
        relatedResorts = $http.get($rootScope.Api('relatedResorts', resort)).then(
            function (result) {
                return result;
            }
        );
        return relatedResorts;
    };

    //fetches resort information
    httpService.getBookingData = function (url) {
        bookInfo = $http.get(url)
            .then(function (result) {
                return result;
            })
            .catch(function () {
                return result;
            });
        return bookInfo;
    };

    //fetches tripadvisor ratings
    httpService.fetchTripadvisorRatings = function () {
        resortRating = $http.get('http://api.tripadvisor.com/api/partner/2.0/location/60745/hotels?key=92e0db3d9b0f4457959b9e18d9deb746').then(
            function (result) {
                return result.data;
            }
        );
        return resortRating;
    };

    //POST
    //payment and guest post, fetches information for confirmation
    httpService.bookPost = function (data, url) {
        confirmationInfo = $http({
            method: 'POST',
            url: url,
            data: data,
        })
        .then(
            function (result) {
                return result;
            }
        );
        return confirmationInfo;
    };

    httpService.paymentSubmission = function (data, url) {
        paymentInfo = $http({
            method: 'POST',
            url: url,
            data: data,
        })
        .then(
            function (result) {
                return result;
            }
        );
        return paymentInfo;
    };

    httpService.getLocationInfo = function () {
        locationInfo = $http.get($rootScope.Api('locations')).then(
            function (result) {
                return result;
            }
        );
        return locationInfo;
    };

    httpService.sundanceInquiry = function (request) {
        return $http.post($rootScope.Api('sundance'), request);
    }

    httpService.hasPendingRequests = function () {
        return $http.pendingRequests.length > 0;
    };

    return httpService;
}]);
