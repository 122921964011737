//FACTORY declaration
resortModule.factory('fancyboxService', ['$http', '$templateRequest', '$compile', '$document', '$swipe', 'resortImages', function ($http, $templateRequest, $compile, $document, $swipe, resortImages) {

    //variables for factory
    var fancyboxService = {};

    //opens content such as resort map from controller
    fancyboxService.openFancyContent = function (template) {
        $.fancybox.open({
            content: template,
            type: 'html',
            fitToView: true,
            maxWidth: 960,
            maxHeight: 400,
            minWidth: '100%',
            minHeight: '100%',
            width: '100%',
        });
    };

    //opens content given a url
    fancyboxService.openURL = function (url, scope) {
        $templateRequest(url).then(function (html) {
            var template = angular.element(html);
            $compile(template)(scope);

            $.fancybox.open({},{
                content: template,
                autoSize: false,
                beforeLoad: function () {
                    this.width = 312;
                    this.height = 419;
                }
            });
        });
    }

    //opens map
    fancyboxService.openMap = function (map_url) {
        $.fancybox([
                map_url,
            ],{
            'type': 'image',
            fitToView: true,
            width: '100%',
        });
    };

    fancyboxService.getMap = function (scope) {
        var url = 'templates/resorts/fancybox/map.html';
        $http.get(url).then(function (response) {
            if (response.status === 200) {
                //grabs returned data
                var template = angular.element(response.data);
                $compile(template)(scope);
                fancyboxService.openFancyContent(template);
            }
        });
    };

    fancyboxService.swipe = function () {
        var startX, startY, endX, endY;

        function compare (start, end) {
            if (start < end) {
                $.fancybox.prev( 'right' );
            } else if (start > end) {
                $.fancybox.next( 'left' );
            }
        }

        function bind () {
            $swipe.bind($document, {
                'start': function(coords) {
                    startX = coords.x;
                },
                'end': function (coords) {
                    endX = coords.x;
                    compare(startX, endX);
                }
            });
        }
        bind();
    };

    fancyboxService.openGalleryImages = function (index, images) {
        var imageArray = [];
        var properties = {
            fitToView: true,
            width: '100%',
            maxWidth: 960,
            helpers: {
                overlay: {
                    locked: true
                }
            },
            afterShow: fancyboxService.swipe(),
            afterClose: function () {
                $document.unbind('mousedown mousemove mouseup touchstart touchmove touchend touchcancel');
            }
        };
        //create images array
        for (var i = 0; i < images.length; i++) {
            imageArray.push({href: images[i].src
                + '/'
                + images[i].resort_id
                + '/gallery/'
                + images[i].file_name
            });
        }
        //if index is given, add it to properties
        if (index) {
            properties.index = index;
        }
        //open fancybox
        $.fancybox.open(imageArray, properties);
    };

    fancyboxService.close = function () {
        $.fancybox.close();
    };

    return fancyboxService;
}]);
