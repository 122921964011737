/*global resortModule, Modernizr, $*/
resortModule.run(['$rootScope', function ($rootScope) {
    $rootScope.modernizer = function () {
        function notSupported() {
            $("svg.vc-logo").replaceWith('<img src="images/logo.png" alt="vacationcandy.com" class="vc-logo"/>');
            $("svg.fb").replaceWith('<img class="social fb" src="images/social/social-fb.png" alt="facebook social"/>');
            $("svg.twitter").replaceWith('<img class="social twitter" src="images/social/social-t.png" alt="twitter social"/>');
            $("svg.pinterest").replaceWith('<img class="social pinterest" src="images/social/social-pin.png" alt="pinterest social"/>');
            $("svg.mobile-menu").replaceWith('<img class="mobile-menu" src="images/mobile-menu.png" alt="menu"/>');

            //arrows
            $("svg.arrow:not(.arrow-left)").replaceWith('<span class="fa arrow fa-angle-right"></span>');
            $("svg.arrow.arrow-left").replaceWith('<span class="fa arrow fa-angle-left"></span>');

            $("#location").removeClass("location");
            $("#location").addClass("location-png");
            $("#resort").removeClass("resort");
            $("#resort").addClass("resort-png");
        }
        if (!Modernizr.svg) {
            notSupported();
        }
    };
}]);