/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').filter('timeStampDate', TimeStampDate);

    ////////////////////////////////////////////////////////////////////////////

    function TimeStampDate() {
        return function (timeStamp) {
            var date = new Date();
            date.setFullYear(timeStamp.substring(0, 4));
            date.setMonth(timeStamp.substring(5, 7));
            return date;
        };
    }
}());
