/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').factory('reviewService', ReviewService);

    ReviewService.$inject = ['$http'];

    ////////////////////////////////////////////////////////////////////////////

    function ReviewService($http) {
        var reviewsUrl = '/api/reviews/';
        var createReviewsUrl = '/api/reviews/create';

        var reviewServiceData = {
            start_date: null,
            name: '',
            location: ''
        }

        return {
            getReview: getReview,
            createReview: createReview,
            reviewServiceData: reviewServiceData
        };

        ////////////////////////////////////////////////////////////////////////

        function getReview(token) {
            return $http.get(reviewsUrl + token).then(success, error);
        }

        function createReview(reviewData) {
            reviewServiceData.start_date = reviewData.start_date;
            reviewServiceData.name = reviewData.name;
            reviewServiceData.location = reviewData.location;

            return $http.post(createReviewsUrl, reviewData).then(success, error);
        }

        function success(response) {
            return response.data;
        }

        function error(error) {
            throw new Error('ReviewService Error: ' + error.statusText);
        }
    }
}());
