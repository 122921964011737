resortModule.directive('formattedAddress', [function () {
    return {
        scope: {
            firstname: '=',
            lastname: '=',
            street: '=',
            street2: '=',
            city: '=',
            state: '=',
            zip: '=',
            country: '='
        },
        templateUrl: function (elem, attr) {
            if (attr.type && attr.type === 'inline') {
                return 'templates/general/address-inline.html';
            }
            return 'templates/general/address.html';
        }
    };
}]);
