/*global resortModule, currentYPosition, currentYPosition, elmYPosition, scrollTo*/
/*used on home page and on how-it-works page */

(function () {
    'use strict';

    var hiw = function ($sce, $timeout, scrollService) {

        var self = this;

        //details accordion
        self.details = [
            {
                head: "How rental rates are set",
                body: $sce.trustAsHtml("Reservation Owners set their own rates, including all taxes. Some resorts require an additional occupancy tax or fee and are payable to the resort at checkout. If applicable, these fees will be noted on the resort's page."),
                //open: "true"
            },{
                head: "Why reservations are rented out weekly",
                body: $sce.trustAsHtml("Our discounted reservation inventory is comprised of week-long reservations that timeshare owners have decided not to use. Guests are welcome to book back-to-back weeks through VacationCandy or book additional nights directly through the resort. Any stay longer than 7 nights may require a room change.")
            },{
                head: "How rental confirmations work",
                body: $sce.trustAsHtml("Once your reservation request is received, owners usually are able to confirm the reservation within 24-48 hours. If an owner is unable to fulfill your request, we will notify you of any alternative options that may be available.")
            },{
                head: "Payment terms",
                body: $sce.trustAsHtml("Full payment is due at the time of booking. Reservations are non-refundable and can not be modified.")
            },{
                head: "VacationCandy saves you up to 70%",
                body: $sce.trustAsHtml("Typically VacationCandy rentals are 30-70% less expensive than booking directly through a resort.")
            },{
                head: "Payment security",
                body: $sce.trustAsHtml("Your credit card information is encrypted once you click to request your reservation. Only the last 4 digits of your card number are available as a reference for our customer service team.")
            },{
                head: "Modifying rental dates",
                body: $sce.trustAsHtml("Normally, Reservation Owners need to book their timeshare week 12-13 months in advance. For this reason, owners are unable to adjust their rental dates.")
            },{
                head: "Cancellations and travel insurance",
                body: $sce.trustAsHtml("Owners are paid at the time of reservation confirmation. This means we are unable to offer refunds for canceled reservations.<br><br> We recommend purchasing travel insurance through CSA Travel Protection to help cover any unexpected illnesses, medical emergencies, natural disasters, adverse weather, delayed flights, road closures or lost or damaged luggage that could hinder your vacation plans. To learn more about travel insurance, reach out to CSA directly at 866-999-4018 or review a sample <a href='http://www.csatravelprotection.com/certpolicy.do?product=G-330CSA' target='_blank'>Description of Coverage/Policy</a>.")
            },
        ];

        //scroll
        self.gotoOffer = function (targetId) {
            var scrollDiv = $('.fancybox-inner');
            if (scrollDiv.length) {
                scrollDiv.scrollTop($('#' + targetId).offset().top);
            } else {
                scrollService.scrollTo(targetId);
            }
        };

        //steps navigation active class
        self.step = 1;
        self.hiwNav = function ($event, id) {
            var target = $event.currentTarget;
            //removes active class and adds it to selected element for numbered lis
            $(target).siblings().removeClass('active');
            $(target).addClass('active');
            //removes active class and adds it to selected content element
            $('.hiw-page .middle').removeClass('active');
            $('#' + id).addClass('active');
        };

        //add animate class to accordion
        self.animate = function () {
            $(".faqs .panel-collapse").addClass('animate');
        };

        //after DOM loads
        $timeout(function () {
            self.animate();
        });
    };

    //dependency injection
    hiw.$inject = ['$sce', '$timeout', 'scrollService'];

    //controller declaration
    angular.module('resortApp').controller('hiwController', hiw);

}());
