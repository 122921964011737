//FACTORY declaration
resortModule.factory('flashService', ['$timeout', function ($timeout) {

    //variables for factory
    var flashService = {};

    //error messages
    flashService.error = function (message) {
        var div = "#feedback";
        $(div).removeClass('dismissed success').addClass('fail'); //message located in footer
        if (message) {
             $(div + " p.fail").html(message.replace(/(?:\r\n|\r|\n)/g, '<br>'));
        } else {
            $(div + " p.fail").html("Is everything filled out correctly?");
        }
    };

    flashService.success = function () {
        var div = "#feedback";
        $(div).removeClass('dismissed fail success');
        $timeout(function(){
            $(div).addClass('success'); //message located in footer
        });
    };

    flashService.dismiss = function () {
        $("#feedback").addClass('dismissed');
    }

    return flashService;
}]);