/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').factory('resortService', ResortService);

    ResortService.$inject = ['http', '$q'];

    ////////////////////////////////////////////////////////////////////////////

    function ResortService(http, $q) {
        var base = '/api',
            resortBase = base + '/resorts';

        var resort = null,
            relatedResorts = null,
            roomTypes = null,
            images = null,
            faqs = null,
            notices = null,
            reviews = null;

        return {
            resort: resort,
            relatedResorts: relatedResorts,
            fetchResort: fetchResort,
            fetchResortFromSlug: fetchResortFromSlug,
            fetchRelatedResorts: fetchRelatedResorts,
            fetchRoomTypes: fetchRoomTypes,
            fetchImages: fetchImages,
            fetchFaqs: fetchFaqs,
            fetchNotices: fetchNotices,
            fetchReviews: fetchReviews
        };

        ////////////////////////////////////////////////////////////////////////

        function fetchResort(resortId) {
            if (resort !== null && resort.id === resortId) {
                return $q(function (resolve) {
                    resolve(resort);
                });
            }

            return http.get(resortBase + '/' + resortId).then(
                function (result) {
                    resort = result.data;
                    return resort;
                }
            );
        }

        function fetchResortFromSlug(slug) {
            return http.get(resortBase + '/slug/' + slug).then(
                function (result) {
                    resort = result.data;
                    return resort;
                },
                function (error) {
                    console.error(error);
                }
            );
        }

        function fetchRelatedResorts(destinationId) {
            return http.get(resortBase + '/destination/' + destinationId).then(
                function (result) {
                    relatedResorts = result.data;
                    return relatedResorts;
                },
                function (error) {
                    console.error(error);
                }
            );
        }

        function fetchRoomTypes(resortId) {
            return http.get(resortBase + '/' + resortId + '/roomtypes').then(
                function (result) {
                    roomTypes = result.data;
                    return roomTypes;
                },
                function (error) {
                    console.error(error);
                }
            );
        }

        function fetchImages(resortId) {
            return http.get(resortBase + '/' + resortId + '/images').then(
                function (result) {
                    images = result.data;
                    return images;
                },
                function (error) {
                    console.error(error);
                }
            );
        }

        function fetchFaqs(resortId) {
            return http.get(resortBase + '/' + resortId + '/faqs').then(
                function (result) {
                    faqs = result.data;
                    return faqs;
                },
                function (error) {
                    console.error(error);
                }
            );
        }

        function fetchNotices(resortId) {
            return http.get(resortBase + '/' + resortId + '/notices').then(
                function (result) {
                    notices = result.data;
                    return notices;
                },
                function (error) {
                    console.error(error);
                }
            );
        }

        function fetchReviews(resortId) {
            return http.get(resortBase + '/' + resortId + '/reviews').then(
                function (result) {
                    reviews = result.data;
                    return reviews;
                },
                function (error) {
                    console.error(error);
                }
            );
        }
    }
}());
