resortModule.directive('dateRange', function () {
    return {
        restrict: 'A',
        link: function(scope, elm, attrs) {
            //if nothing has been selected, remove classes
            function removeClasses () {
                $("td").mouseleave(function ($event) {
                    $('.highlight_end').removeClass('highlight_end');
                });
                $('.endDate td').mouseleave(function ($event) {
                    $($event.target).parents('.ui-datepicker-group').removeClass('finalBlock');
                    $($event.target).parent().removeClass('finalRow');
                    $('.endDate .ui-datepicker td').removeClass('highlight');
                });
                $('.ui-datepicker-group').mouseleave(function ($event) {
                    $($event.target).removeClass('finalBlock');
                    $('.finalRow').removeClass('finalRow');
                    $('.highlightRow').removeClass('highlightRow');
                    $('td').removeClass('highlight');
                });
            }
            function addClasses () {
                $(".endDate td").mouseenter(function ($event) {
                    $($event.target).parents('.ui-datepicker-group').addClass('finalBlock');
                    $('.finalBlock').prevAll().find('td:not(.ui-datepicker-unselectable):not(.ui-datepicker-current-day)').addClass('highlight');
                    $($event.target).parent().addClass('finalRow');
                    $('.finalRow').prevAll().find('td:not(.ui-datepicker-unselectable):not(.ui-datepicker-current-day)').addClass('highlight');
                    $($event.target).prevAll('td:not(.ui-datepicker-unselectable):not(.ui-datepicker-current-day)').addClass('highlight');
                    $($event.target).not(".ui-datepicker-current-day").addClass('highlight_end');
                    $('.highlight').parent().addClass('highlightRow');
                });
            }

            //toggles highlighter on or off on hover
            //add classes on mouseenter
            elm.on('mouseenter',function($event) {
                //if (!scope.co){
                    addClasses($event);
                    removeClasses($event);
                //}
            });
        }
    };
});
