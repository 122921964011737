/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').component('app', {
        controller: AppController,
        templateUrl: 'templates/app.component.html'
    });

    AppController.$inject = ['$state', '$transitions', 'screen', 'resortService', 'listingService'];

    ////////////////////////////////////////////////////////////////////////////

    function AppController($state, $transitions, screen, resortService, listingService) {
        var self = this;

        self.checkin = null;
        self.checkout = null;
        self.resortId = null;

        self.ready = false;
        self.isMobile = false;
        self.isMobileSearch = false;

        self.showSearchBox = true;

        self.$onInit = onInit;
        self.setScreenSize = setScreenSize;

        $transitions.onBefore({to: 'app.resorts.slug'}, function (transition) {
            if (self.checkin && self.checkout) {
                var target = 'app.resorts.slug-dates';
                var params = {slug: transition.params().slug,
                            startDate: self.checkin,
                            endDate: self.checkout};

                return transition.router.stateService.target(target, params);
            }
        });

        $transitions.onSuccess({to: 'app.resorts.**'}, function (transition) {
            onParamsChange(transition.params());
        });

        ////////////////////////////////////////////////////////////////////////

        function onInit() {
            onParamsChange($state.params);
        }

        function onParamsChange(params) {
            if (params.slug) {
                getResortFromSlug(params.slug);
            }
            if (params.startDate) {
                self.checkin = params.startDate;
            }
            if (params.endDate) {
                self.checkout = params.endDate;
            }
            if (params.listingId) {
                listingService.fetchLead(params.listingId, params.rentalId).then(function (listing) {
                    self.resortId = listing.resort_id;
                    self.checkin = new Date(listing.checkin);
                    self.checkout = new Date(listing.checkout);
                });
            }
        }

        function setScreenSize(size) {
            self.isMobileSearch = size < screen.sizes.lg;
            self.isMobile = size < screen.sizes.md;
            self.ready = true;
        }

        function getResortFromSlug(slug) {
            resortService.fetchResortFromSlug(slug).then(function (resort) {
                self.resortId = resort.id;
            });
        }
    }
}());
