/*global resortModule*/
/*global angular*/
/*used on book page and confirmation page*/

(function () {
    'use strict';

    angular.module('resortApp').component('createTestimonial', {
        templateUrl: 'templates/testimonials/testimonials-create.html',
        controller: CreateTestimonialController,
        bindings: {
            reservation: '<'
        }
    });

    CreateTestimonialController.$inject = ['$stateParams', 'testimonialService', 'flashService', 'reviewService', 'scrollService'];

    /////////////////////////////////////////////////////////////////////////////////////////

    function CreateTestimonialController($stateParams, testimonialService, flashService, reviewService, scrollService) {

        var self = this;

        self.testimonialExists = false;
        self.documentReady = false;

        // Default values for testimonial form
        self.testimonialForm = {
            reservation_id: null,
            rating: null,
            title: "",
            body: "",
            name: "",
            location: ""
        };

        self.$onInit = onInit;
        self.rate = rate;
        self.submit = submit;

        /////////////////////////////////////////////////////////////////////////////////////

        function onInit() {
            self.testimonialForm.reservation_id = self.reservation.reservation_id;
            self.testimonialForm.name = reviewService.reviewServiceData.name;
            self.testimonialForm.location = reviewService.reviewServiceData.location;

            testimonialService.getTestimonial($stateParams.token).then(function (testimonial) {
                self.testimonialExists = testimonial.exists;
                self.documentReady = true;
            });
        }

        function rate(rating) {
            self.testimonialForm.rating = rating;
        }

        function submit() {
            testimonialService.createTestimonial(self.testimonialForm).then(function (response) {
                if (response.created === true) {
                    scrollService.scrollTop();
                    self.testimonialExists = true;
                    flashService.success(response.message);
                } else {
                    flashService.error(response.message);
                }
            });
        }
    }
}());
