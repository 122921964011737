/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').component('mobileHeader', {
        controller: MobileHeaderController,
        templateUrl: 'templates/header/mobile.html'
    });

    ////////////////////////////////////////////////////////////////////////////

    function MobileHeaderController() {
        var self = this;

        self.open = false;
        self.toggleHeader = toggleHeader;

        ////////////////////////////////////////////////////////////////////////

        function toggleHeader() {
            self.open = !self.open;
        }
    }
}());
