resortModule.factory('validationService', function () {
    return {
        luhn: function (number) {
            var num = number.replace(/\D/g,''),
            len = num.length,
            parity = len % 2,
            total = 0,
            i;

            for (i = 0; i < len; i++) {
                var digit = parseInt(num.charAt(i), 10);
                if (i % 2 === parity) {
                    digit *= 2;
                    if (digit > 9) {
                        digit -= 9;
                    }
                }
                total += digit;
            }

            return total !== 0 && total % 10 === 0;
        },
        setValidity: function (field, value) {
            if (value) {
                field.$setValidity('required', this.luhn(value));
            }
        }
    };
});
