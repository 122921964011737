/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').factory('covidNoticesService', CovidNoticesService);

    CovidNoticesService.$inject = ['$http'];

    ////////////////////////////////////////////////////////////////////////////

    function CovidNoticesService($http) {
        var covidNoticesUrl = '/api/covid19';

        return {
            getNotices: getNotices,
        };

        ////////////////////////////////////////////////////////////////////////

        function getNotices() {
            return $http.get(covidNoticesUrl).then(success, error);
        }

        function success(response) {
            return response.data;
        }

        function error(error) {
            throw new Error('CovidNoticesService Error: ' + error.statusText);
        }
    }
}());
