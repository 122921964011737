/* modernizer */
resortModule.directive('ifDateNoSupportCi', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            if (!Modernizr.touch || !Modernizr.inputtypes.date) {
                $timeout(function () {
                    $('input[type=date]').attr('type', 'text');
                    element.datepicker({
                        dateFormat: 'yy-mm-dd',
                        minDate: 0,
                        onSelect: function (date) {
                            scope.ci = new Date(date);
                        }
                    });
                });
            }
        }
    };
}]);
resortModule.directive('ifDateNoSupportCo', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            if (!Modernizr.touch || !Modernizr.inputtypes.date) {
                $timeout(function () {
                    element.datepicker({
                        dateFormat: 'yy-mm-dd',
                        minDate: scope.ci,
                        showCurrentAtPos: 1,
                        onSelect: function (date) {
                            scope.co = new Date(date);
                        }
                    });
                });
            }
        }
    };
}]);

/* datepicker init */
resortModule.directive('beginDatepicker', ['$window', function ($window) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            var numMonths = [24,1];
            attrs.$observe('months', (function (newValue) {
                //update datepicker numberOfMonths Value
                numMonths = parseInt(newValue, 10);
                element.datepicker("option", "numberOfMonths", numMonths);
            }), true);
            function startDatepicker () {
                element.datepicker({
                    minDate: 0,
                    numberOfMonths: numMonths,
                    showOtherMonths: true,
                    nextText: " ",
                    showCurrentAtPos: 0,
                    beforeShowDay: function(date) {
                        if (!scope.ci) {
                            var todayFormated = new Date();
                            todayFormated.setHours(0);
                            todayFormated.setMinutes(0);
                            todayFormated.setSeconds(0);
                            if (Date.parse(date) === Date.parse(todayFormated)) {
                                return [true, "today"];
                            }
                        }

                        if (scope.co) {
                            var coFormated = new Date(scope.co);
                            coFormated.setHours(0);
                            coFormated.setMinutes(0);
                            coFormated.setSeconds(0);
                            if (date.getTime() === coFormated.getTime()) {
                                return [true, "highlight_end"];
                            }
                        }

                        return [true, '', ''];
                    },
                    onSelect: function (date) {
                        var previousCi = scope.ci !== '' ? new Date(scope.ci) : new Date();
                        var selected = new Date(date);
                        var yearMod = 12 * (selected.getFullYear() - previousCi.getFullYear());
                        scope.coPos = ((selected.getMonth() + yearMod) - previousCi.getMonth() - scope.ciOffset) % 3;

                        scope.$apply(function () {
                            ngModel.$setViewValue(new Date(date));
                        });
                    },
                    onChangeMonthYear: function (year, month, inst) {
                        var currentDate = new Date($( ".ci" ).datepicker("getDate"));
                        var yearMod = 12 * (year - currentDate.getFullYear());
                        scope.ciOffset = (month + yearMod) - currentDate.getMonth() - 1;
                    }
                });
            }
            scope.$watch('checkinSelect', (function (newValue) {
                if (newValue) {
                    startDatepicker();
                    if (scope.ci) {
                        $('.ci').datepicker("setDate", scope.ci);
                    }
                } else {
                    $('.ci').datepicker('destroy');
                }
            }));
        }
    };
}]);
resortModule.directive('endDatepicker', ['$window', function ($window) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            var numMonths = [24,1];

            //grab the number of months attribute value
            attrs.$observe('months', (function (newValue) {
                //update datepicker numberOfMonths Value
                numMonths = parseInt(newValue);
                element.datepicker("option", "numberOfMonths", numMonths);
            }), true);

            //change month position
            function changeMonthPosition () {
                var ci = new Date(scope.ci);
                var co = new Date(scope.co);
                var checkinMonth = ci.getMonth();
                var checkoutMonth = co.getMonth();
                var difference;
                var position;
                //check to see which number is greater
                if (checkoutMonth >= checkinMonth) {
                    difference = checkoutMonth - checkinMonth;
                } else {
                    difference = (checkoutMonth + 12) - checkinMonth;
                }
                //test how great the difference is and set position
                if (difference >= 2) {
                    position = 2;
                } else if (difference === 1) {
                    position = 1;
                } else {
                    position = 0;
                }
                //DOM
                scope.$evalAsync(function () {
                    $( ".co" ).datepicker( "option", "showCurrentAtPos", position);
                });
            }

            //initialize datepicker
            function startDatepicker () {
                element.datepicker({
                    dateFormat: 'm/d/yy',
                    numberOfMonths: numMonths,
                    minDate: scope.ci,
                    nextText: " ",
                    showOtherMonths: true,
                    gotoCurrent: true,
                    showCurrentAtPos: scope.coPos,
                    beforeShowDay: function(date) {
                        var ciFormated = new Date(scope.ci);
                        ciFormated.setHours(0);
                        ciFormated.setMinutes(0);
                        ciFormated.setSeconds(0);
                        if (date.getTime() === ciFormated.getTime()) {
                            return [true, "highlight_begin"];
                        } else {
                            return [true, '', ''];
                        }
                    },
                    onSelect: function (date) {
                        scope.$apply(function () {
                            ngModel.$setViewValue(new Date(date));
                        });
                    }
                });
            }

            //watch
            scope.$watch('ci', (function (newValue) {
                //update datepicker mindate to checkin value
                element.datepicker("option", "minDate", newValue);
            }), true);
            scope.$watch('checkoutSelect', (function (newValue) {
                if (newValue) {
                    startDatepicker();
                    if (scope.co) {
                        $('.co').datepicker("setDate", scope.co);
                    }
                } else {
                    scope.coPos = $('.co').datepicker("option", "showCurrentAtPos");
                    $('.co').datepicker('destroy');
                }
                //change the month position if both check in and check out are selected
                if (scope.co && scope.ci) {
                    changeMonthPosition();
                }
            }));
        }
    };
}]);
resortModule.directive('jqdatepicker', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
         link: function (scope, element, attrs, ngModelCtrl) {
            element.datepicker({
                dateFormat: 'mm/dd/yy',
                beforeShow: function(input, inst) {
                    $('.ui-datepicker').addClass('form-datepicker');
                },
                onSelect: function (date) {
                    scope.$apply(function () {
                        ngModelCtrl.$setViewValue(new Date(date));
                    });
                }
            });
        }
    };
});

