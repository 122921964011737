resortModule.directive('yearDrop', function () {
    function getYears(offset, range){
        var currentYear = new Date().getFullYear();
        var years = [];
        for (var i = 0; i < range + 1; i++){
            years.push(currentYear + offset + i);
        }
        return years;
    }
    return {
        restrict: "A",
        link: function (scope, element, attrs) {
            scope.years = getYears(+attrs.offset, +attrs.range);
            scope.selected = scope.years[0];
            scope.year = scope.years[0];
        },
        scope: {
            ngModel : '=',
        },
        template: '<select name="expiration_year" ng-model="ngModel" ng-options="y for y in years" required><option value>Year</option></select>',
    };
});
