/* global resortModule */
/* note : on gooey post, url is redirected to resorts page, see gooey controller if this runs into conflicts */

resortModule.config(['$stateProvider', '$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.when('', '/');
    $urlRouterProvider.otherwise('/404');

    function scrollToTop() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    $stateProvider
        .state('app', {
            url: '/',
            reloadOnSearch: false,
            views: {
                'header': {
                    component: 'header'
                },
                'content': {
                    component: 'app'
                },
                'footer' : {
                    component: 'email'
                }
            }
        })

        .state('app.404', {
            url: '404',
            templateUrl: 'templates/404.html',
            onEnter: function () {
                scrollToTop();
            }
        })

        //resorts page
        .state('app.resorts', {
            url: 'resorts',
            reloadOnSearch: false,
            template: '<resorts ng-if="$ctrl.resortId" checkin="$ctrl.checkin" checkout="$ctrl.checkout" resort-id="$ctrl.resortId" is-mobile="$ctrl.isMobile"></resorts>',
        })
        //slug and default dates
        .state('app.resorts.slug', {
            url: '/:slug',
        })
        //slug with dates
        .state('app.resorts.slug-dates', {
            url: '/:slug/checkin/:startDate/checkout/:endDate',
        })
        //stack and rental ids when only one rental is in the stack
        .state('app.resorts.listing-ind', {
            url: '/listing/:listingId/rental/:rentalId',
        })
        //stack id
        .state('app.resorts.listing', {
            url: '/listing/:listingId',
        })

        //main navigation pages
        .state('app.how-it-works', {
            url: 'how-it-works',
            reloadOnSearch: false,
            templateUrl: 'templates/how-it-works.html',
            controller: 'hiwController as hiw',
            onEnter: function () {
                scrollToTop();
            }
        })
        .state('app.destinations', {
            url: 'destinations',
            templateUrl: 'templates/destinations/destinations.html',
            controller: 'destinationsController'
        })
        .state('app.destinations.destSlug', {
            url: '/kayak/:destSlug',
            templateUrl: 'templates/destinations/kayak.html',
            controller: 'kayakController as kayak'
        })

        //reviews
        .state('app.reviews', {
            url: 'reviews',
            reloadOnSearch: false,
            templateUrl: 'templates/reviews.html',
            onEnter: function () {
                scrollToTop();
            }
        })
        .state('app.testimonials', {
            url: 'testimonials',
            templateUrl: 'templates/reviews.html',
            onEnter: function () {
                scrollToTop();
            }
        })
        .state('app.review-container', {
            abstract: true,
            reloadOnSearch: false,
            component: 'reviewContainer',
            resolve: {
                reservation: ['$stateParams', 'reservationService', function ($stateParams, reservationService) {
                    return reservationService.getReservation($stateParams.token);
                }]
            },
            onEnter: function () {
                scrollToTop();
            }
        })
        .state('app.review-container.review', {
            url: 'reviews/:token',
            component: 'createReview'
        })
        .state('app.review-container.testimonial', {
            url: 'testimonials/:token',
            component: 'createTestimonial'
        })

        .state('app.cancellation', {
            url: 'cancellation',
            reloadOnSearch: false,
            templateUrl: 'templates/cancellation.html',
            onEnter: function () {
                scrollToTop();
            }
        })

        .state('app.sitemap', {
            url: 'sitemap',
            reloadOnSearch: false,
            component: 'sitemap',
            onEnter: function () {
                scrollToTop();
            }
        })

        .state('app.terms', {
            url: 'terms',
            reloadOnSearch: false,
            templateUrl: 'templates/terms.html',
            onEnter: function () {
                scrollToTop();
            }
        })
        .state('app.terms-confirm', {
            url: 'terms/confirmation/:token',
            reloadOnSearch: false,
            templateUrl: 'templates/terms-accept.html',
            controller: 'termsController',
            onEnter: function () {
                scrollToTop();
            }
        })

        //book confirmation
        .state('app.book-confirmation-token', {
            url: 'book/confirmation',
            reloadOnSearch: false,
            templateUrl: 'templates/book/confirmation.html',
            controller: 'confirmController',
            onEnter: function () {
                scrollToTop();
            }
        })
        .state('app.book-ind', {
            url: 'book/:listing/rental/:rental',
            reloadOnSearch: false,
            component: 'book',
            onEnter: function () {
                scrollToTop();
            }
        })
        //book with time token
        .state('app.book-token', {
            url: 'book/:listing/:token',
            reloadOnSearch: false,
            component: 'book',
            onEnter: function () {
                scrollToTop();
            }
        })
        //book page
        .state('app.book', {
            url: 'book/:listing',
            reloadOnSearch: false,
            component: 'book',
            onEnter: function () {
                scrollToTop();
            }
        })
}]);
