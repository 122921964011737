/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').component('resortReviews', {
        templateUrl: 'templates/reviews/reviews.component.html',
        controller: ReviewsController,
        bindings: {
            reviews: '<'
        }
    });

    ReviewsController.$inject = [
        '$sanitize', 'scrollService'
    ];

    ////////////////////////////////////////////////////////////////////////////

    function ReviewsController($sanitize, scrollService) {
        var self = this;
        var numPerPage = 4;
        var reviewContainer = 'resort_reviews';

        self.pageIndex = 0;
        self.numButtons = 0;
        self.maxPage = 0;
        self.pagedReviews = [];
        self.page = [];
        self.buttonNums = [];

        self.$onInit = onInit;
        self.$onChanges = onInit;
        self.hasReviews = hasReviews;
        self.goToPage = goToPage;
        self.nextPage = nextPage;
        self.previousPage = previousPage;
        self.disableButton = disableButton;

        ////////////////////////////////////////////////////////////////////////

        function onInit() {
            self.pageIndex = 0;
            self.reviews = self.reviews.sort(function (a, b) {
                var aStartDate = a.start_date ? new Date(a.start_date) : new Date(a.created);
                var bStartDate = b.start_date ? new Date(b.start_date) : new Date(b.created);
                return aStartDate < bStartDate ? 1 : -1;
            });
            self.pagedReviews = pageReviews(self.reviews, numPerPage);
            self.page = self.pagedReviews[self.pageIndex];
            self.numButtons = Math.min(self.pagedReviews.length, 3);
            self.maxPage = (self.pagedReviews.length - 1);
            self.buttonNums = makeButtonNums(self.pageIndex);
        }

        function hasReviews() {
            return self.reviews && self.reviews.length > 0;
        }

        function disableButton(buttonIndex) {
            return self.pageIndex === buttonIndex;
        }

        function pageReviews(reviews, numPerPage) {
            var pagedReviews = [];
            var index = 0;

            while (index + numPerPage < reviews.length) {
                pagedReviews.push(reviews.slice(index, index + numPerPage));
                index += numPerPage;
            }
            pagedReviews.push(reviews.slice(index));

            return pagedReviews;
        }

        function makeButtonNums(index) {
            var startIndex = Math.min(index, self.pagedReviews.length - self.numButtons);
            var returnArr = [];
            for (var i = 0; i < self.numButtons; i += 1) {
                returnArr.push(i + startIndex);
            }
            return returnArr;
        }

        function goToPage(pageIndex) {
            self.pageIndex = pageIndex;
            self.buttonNums = makeButtonNums(Math.max(pageIndex - 1, 0));
            self.page = self.pagedReviews[self.pageIndex];
            scroll();
        }

        function nextPage() {
            self.pageIndex += 1;
            self.buttonNums = makeButtonNums(self.pageIndex);
            self.page = self.pagedReviews[self.pageIndex];
            scroll();
        }

        function previousPage() {
            self.pageIndex -= 1;
            self.buttonNums = makeButtonNums(self.pageIndex);
            self.page = self.pagedReviews[self.pageIndex];
            scroll();
        }

        function scroll() {
            scrollService.scrollTo(reviewContainer);
        }
    }
}());
