/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').factory('http', HttpService);

    HttpService.$inject = ['$http'];

    ////////////////////////////////////////////////////////////////////////////

    function HttpService($http) {
        var requests = 0;

        return {
            get: get,
            post: post,
            hasRequests: hasRequests
        };

        ////////////////////////////////////////////////////////////////////////

        function get(url) {
            requests += 1;
            return $http.get(url).then(function (response) {
                requests -= 1;
                return response;
            });
        }

        function post(url, data) {
            requests += 1;
            return $http.post(url, data).then(function (response) {
                requests -= 1;
                return response;
            });
        }

        function hasRequests() {
            return requests > 0;
        }
    }
}());
