//FACTORY declaration
resortModule.factory('rerouteService', ['locaService', '$state', '$stateParams', '$location', '$rootScope', '$filter', function (locaService, $state, $stateParams, $location, $rootScope, $filter) {

    var rerouteService = {};

     //URL
     rerouteService.changeDateLessResorts = function (slug) {
        if (slug) {
            var args = { slug: slug };
            if ($location.hash()) {
                args['#'] = $location.hash();
            }

            $state.transitionTo("app.resorts.slug", args);
        }
    };

    //update url to show resort and dates
    rerouteService.changeUrl = function () {
        //redirect to resorts page
        if (locaService.startDate && locaService.endDate) {
            var args = {
                slug: $filter('lowercase')(locaService.resortInfo.slug),
                startDate: locaService.startDate,
                endDate: locaService.endDate
            };
            if ($location.hash()) {
                args['#'] = $location.hash();
            }

            $state.transitionTo("app.resorts.slug-dates", args);

        } else {
            rerouteService.changeDateLessResorts(locaService.resortInfo.slug);
        }
    };

    rerouteService.changeLinkHome = function () {
        $state.go('app');
    };

    rerouteService.resortListing = function (id) {
        if (id) {
            var args = { listingId: id };
            $state.transitionTo("app.resorts.listing", args);
        }
    };

    return rerouteService;
}]);
