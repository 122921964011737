/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').component('createReview', {
        templateUrl: 'templates/reviews/reviews.create.html',
        controller: CreateReviewController,
        bindings: {
            reservation: '<'
        }
    });

    CreateReviewController.$inject = [
        '$scope', '$filter', '$stateParams', '$state', 'httpService',
        'fancyboxService', 'flashService', 'reviewService', 'testimonialService',
        'scrollService'
    ];

    ////////////////////////////////////////////////////////////////////////////

    function CreateReviewController($scope, $filter, $stateParams, $state,
        httpService, fancyboxService, flashService, reviewService,
        testimonialService, scrollService) {
        var self = this;

        self.reviewExists = false;
        self.testimonialExists = false;
        self.reviewReady = false;
        self.testimonialReady = false;

        self.reviewForm = {
            reservation_id: null,
            start_date: null,
            rating: null,
            title: "",
            body: "",
            name: "",
            location: ""
        };

        self.$onInit = onInit;
        self.rate = rate;
        self.submit = submit;
        self.mobileTips = mobileTips;
        self.mobileReservationDetails = mobileReservationDetails;
        self.submitted = submitted;
        self.testimonialButtonClicked = testimonialButtonClicked;
        self.documentReady = documentReady;

        ////////////////////////////////////////////////////////////////////////

        function onInit() {
            self.reviewForm.reservation_id = self.reservation.reservation_id;
            self.reviewForm.start_date = $filter('date')(self.reservation.start_date, 'MM/dd/yyyy');
            self.reviewForm.name = self.reservation.guest_first_name;
            if (self.reservation.address) {
                self.reviewForm.location = self.reservation.address[0].city;
                if (self.reservation.address[0].state && self.reservation.address[0].state.name) {
                    self.reviewForm.location += ', ' + self.reservation.address[0].state.name;
                }
            }

            reviewService.getReview($stateParams.token).then(function (review) {
                self.reviewExists = review.exists;
                self.reviewReady = true;
            });

            testimonialService.getTestimonial($stateParams.token).then(function (testimonial) {
                self.testimonialExists = testimonial;
                self.testimonialReady = true;
            });
        }

        function rate(rating) {
            self.reviewForm.rating = rating;
        }

        function submit() {
            reviewService.createReview(self.reviewForm).then(function (response) {
                if (response.created === true) {
                    scrollService.scrollTop();
                    self.reviewExists = true;
                    flashService.success(response.message);
                    $state.go('app.review-container.testimonial', {token: $stateParams.token});
                } else {
                    flashService.error(response.message);
                }
            });
        }

        function mobileTips() {
            fancyboxService.openURL('templates/reviews/review-tips.html', $scope);
        }

        function mobileReservationDetails() {
            fancyboxService.openURL('templates/reviews/review.reservation-details.html', $scope);
        }

        function submitted() {
            return httpService.hasPendingRequests();
        }

        function testimonialButtonClicked() {
            scrollService.scrollTop();
            $state.go('app.review-container.testimonial', {token: $stateParams.token});
        }

        function documentReady() {
            return self.reviewReady && self.testimonialReady;
        }
    }
}());

