/* global resortModule */
resortModule.run(['$rootScope', '$location', '$window', function ($rootScope, $location, $window) {
    $rootScope
        .$on('$viewContentLoaded',
            function (event) {
                if (!$window.ga) {
                    return;
                }

                //when route changes, new page url is sent to google analytics
                $window.ga('send', 'pageview', { page: $location.url() });
                //google analytics
                //set referrer (vrbo or redweek)
                var ref = document.referrer;
                if (ref.match(/^https?:\/\/([^\/]+\.)?vrbo\.com(\/|$)/i)) {
                    _gaq.push(['_setCustomVar', 1, 'referrer', 'VRBO', 1]);
                } else if (ref.match(/^https?:\/\/([^\/]+\.)?redweek\.com(\/|$)/i)) {
                    _gaq.push(['_setCustomVar', 1, 'referrer', 'Redweek', 1]);
                }
            }
        );
}]);
