/*global resortModule*/
resortModule.directive('paginate', ['$compile', 'scrollService', function ($compile, scrollService) {
    'use strict';
    return {
        replace: 'true',
        scope: {
            data: '<',
            numPerPage: '<',
            templateUrl: '<',
        },
        templateUrl: function (element, attrs) {
            return 'templates/' + attrs.templateUrl;
        },

        link: function (scope, element) {
            scope.page = [];
            scope.buttonNums = [];
            scope.buttonCount = 0;
            scope.index = 0;
            scope.maxPage = 0;
            scope.disableButton = disableButton;
            scope.goToPage = goToPage;
            scope.nextPage = nextPage;
            scope.previousPage = previousPage;

            // Build array of pages
            var i = 0;
            var pagedData = [];
            while (i + scope.numPerPage < scope.data.length) {
                pagedData.push(scope.data.slice(i, i + scope.numPerPage));
                i += scope.numPerPage;
            }
            pagedData.push(scope.data.slice(i));

            // Set scope variables
            scope.maxPage = pagedData.length;
            scope.buttonCount = Math.min(scope.maxPage, 3);
            scope.page = pagedData[scope.index];
            scope.buttonNums = makeButtonsArray(scope.index);

            function makeButtonsArray(index) {
                var startIndex = Math.min(index, scope.maxPage - scope.buttonCount);
                var returnArr = [];
                for (var i = 0; i < scope.buttonCount; i += 1) {
                    returnArr.push(i + startIndex);
                }
                return returnArr;
            }

            function disableButton(buttonIndex) {
                return scope.index === buttonIndex;
            }

            function goToPage(index) {
                scope.index = index;
                scope.buttonNums = makeButtonsArray(Math.max(index - 1, 0));
                scope.page = pagedData[scope.index];
                scrollService.scrollTop();
            }

            function nextPage() {
                scope.index += 1;
                scope.buttonNums = makeButtonsArray(scope.index);
                scope.page = pagedData[scope.index];
                scrollService.scrollTop();
            }

            function previousPage() {
                scope.index -= 1;
                scope.buttonNums = makeButtonsArray(scope.index);
                scope.page = pagedData[scope.index];
                scrollService.scrollTop();
            }
        }
    };
}]);
