/*global resortModule*/
resortModule.directive('tmslide', function () {
    return {
        restrict: 'E',
        templateUrl: 'templates/general/testimonials.html'
    };
});
resortModule.directive('terms', function () {
    return {
        restrict: 'E',
        templateUrl: 'templates/terms.html'
    };
});

//HEADER
resortModule.directive('desktopHeader', function () {
    return {
        restrict: 'E',
        templateUrl: 'templates/header/desktop.html',
    };
});
//RESORTS
resortModule.directive('instagram', function () {
    return {
        restrict: 'E',
        templateUrl: 'templates/resorts/instagram.html'
    };
});
resortModule.directive('gmap', function () {
    return {
        restrict: 'E',
        templateUrl: 'templates/resorts/gmap/map.html'
    };
});
resortModule.directive('mapslider', function () {
    return {
        restrict: 'E',
        templateUrl: 'templates/resorts/gmap/slider.html'
    };
});
resortModule.directive('reviewTips', function () {
    return {
        restrict: 'E',
        templateUrl: 'templates/reviews/review-tips.html'
    };
});
resortModule.directive('reviewReservationDetails', function () {
    return {
        restrict: 'E',
        templateUrl: 'templates/reviews/review.reservation-details.html'
    };
});

