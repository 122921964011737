/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').factory('testimonialService', TestimonialService);

    TestimonialService.$inject = ['$http'];

    ////////////////////////////////////////////////////////////////////////////

    function TestimonialService($http) {
        var testimonialUrl = '/api/testimonials';
        var createTestimonialUrl = '/api/testimonials/create';

        return {
            getTestimonial: getTestimonial,
            getTestimonials: getTestimonials,
            createTestimonial: createTestimonial
        };

        ////////////////////////////////////////////////////////////////////////

        function getTestimonial(token) {
            return $http.get(testimonialUrl + '/' + token).then(success, error);
        }

        function getTestimonials() {
            return $http.get(testimonialUrl).then(success, error);
        }

        function createTestimonial(testimonialData) {
            return $http.post(createTestimonialUrl, testimonialData).then(success, error);
        }

        function success(response) {
            return response.data;
        }

        function error(error) {
            throw new Error('TestimonialService Error: ' + error.statusText);
        }
    }
}());
