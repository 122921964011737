/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').component('overview', {
        controller: OverviewController,
        bindings: {
            description: '<'
        },
        templateUrl: 'templates/resorts/overview.html'
    });

    ///////////////////////////////////////////////////////

    function OverviewController() {
        var self = this;

        self.readMore = false;

        self.$onChanges = onChanges;
        self.canReadMore = canReadMore;
        self.setReadMore = setReadMore;

        ///////////////////////////////////////////////////

        function onChanges() {
            self.readMore = false;
        }

        function canReadMore() {
            return !self.readMore && self.description && self.description.length >= 500;
        }

        function setReadMore(readMore) {
            self.readMore = readMore;
        }
    }
}());
