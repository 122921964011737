resortModule.directive("bnDropdown", ['$compile', function ($compile) {
    // Return the directive configuration.
    // --
    // NOTE: ngModel compiles at priority 1, so we will compile at priority 2.
    return ({
        compile: compile,
        priority: 2,
        restrict: "A",
        terminal: true
    });
    // I compile the bnDropdown directive, adding the ngModel directive and
    // other HTML and CSS class hooks needed to execute the dropdown. This
    // assumes that all directives are present (ie, you can't render the
    // dropdown using an ngInclude or any other asynchronous loading).
    function compile(tElement, tAttributes) {
        // Add the ngModel directive using the attribute value of the main
        // directive. This just makes it easier to use (and look nicer in
        // my opinion).
        if (!tAttributes.ngModel) {
            tElement.attr("ng-model", tAttributes.bnDropdown);
        }
        // Prepend the root of the menu (where the selected value is shown
        // when the menu options are hidden).
        tElement.prepend(
            "<div class='dropdown-root'>" + "<div class='dropdown-label'></div>" + "</div>"
        );
        // Add CSS hooks. Since we're in the compiling phase, these CSS hooks
        // will automatically be picked up by any nested ngRepeat directives;
        // that's what makes the compile phase (and AngularJS) so player!
        tElement
            .addClass("m-dropdown")
            .children("ul")
                .addClass("dropdown-options")
                .find("li")
                    .addClass("dropdown-option dropdown-label");
        if (tAttributes.caret) {
            tElement.addClass("dropdown-caret");
        }
        // Since we're using TERMINAL compilation, we have to explicitly
        // compile and link everything at a lower priority. This will compile
        // the newly-injected ngModel directive as well as all the nested
        // directives in the menu.
        var linkSubtree = $compile(tElement, null, 2);
        return (link);
        // When the dropdown is linked, we have to link the explicitly
        // compiled portion of the DOM.
        function link (scope) {
            linkSubtree(scope);
        }
    }
}]);
// Now that we've compiled the directive (in the above priority), we need to work
// with the ngModelController to update and reactive to View-Model changes.
resortModule.directive("bnDropdown", ['$parse', '$document', function ($parse, $document) {
    // Return the directive configuration.
    return ({
        link: link,
        require: "ngModel",
        restrict: "A"
    });
    // I bind the JavaScript events to the local scope.
    function link(scope, element, attributes, ngModelController) {
        // Cache DOM references.
        // --
        // NOTE: We are NOT caching the LI nodes as those are dynamic. We'll
        // need to query for those just-in-time when they are needed.
        var dom = {
            module: element,
            root: element.find("div.dropdown-root"),
            rootLabel: element.find("div.dropdown-root div.dropdown-label"),
            options: element.find("ul.dropdown-options")
        };
        // I am the value that will be put in the menu root if we cannot
        // find an option with the matching ngModel value.
        var placeholder = (attributes.placeholder || "&nbsp;");
        // When the user clicks outside the menu, we have to close it.
        $document.on("mousedown", handleDocumentMouseDown);
        // When the user clicks the root, we're going to toggle the menu.
        dom.root.on("click", handelRootClick);
        // When the user clicks on an option, we're going to select it.
        // This must use event delegation (only available in jQuery) since
        // the options are dynamic.
        dom.options.on("click", ".dropdown-option", handleOptionClick);
        // When the scope is destroyed, we have to clean up.
        scope.$on("$destroy", handleDestroyEvent);
        // When the ngModel value is changed, we'll have to update the
        // rendering of the dropdown menu to reflect the ngModel state.
        ngModelController.$render = renderSelectedOptionAsync;
        // ---
        // PUBLIC METHODS.
        // ---
        // I clean up the directive when it is destroyed.
        function handleDestroyEvent() {
            $document.off("mousedown", handleDocumentMouseDown);
        }
        // I handle the mouse-down event outside the menu. If the user clicks
        // down outside the menu, we have to close the menu.
        function handleDocumentMouseDown(event) {
            var target = angular.element(event.target);
            // NOTE: .closest() requires jQuery.
            if (isOpen() && !target.closest(dom.module).length) {
                hideOptions();
            }
        }
        // I handle the selection of an option by a user.
        function handleOptionClick(event) {
            // When the user selects an option, we have to tell the
            // ngModelController. And, since we are changing the View-Model
            // from within a directive, we have to use $apply() so that
            // AngularJS knows that something has been updated.
            scope.$apply(
                function changeModel() {
                    hideOptions();
                    var option = angular.element(event.target).closest("li");
                    ngModelController.$setViewValue(getOptionValue(option));
                    // $setViewValue() does not call render explicitly. As
                    // such we have to call it explicitly in order to update
                    // the content of the menu-root.
                    renderSelectedOption();
                }
            );
        }
        // I toggle the dropdown options menu.
        function handelRootClick(event) {
            isOpen() ? hideOptions() : showOptions();
        }
        // I get called implicitly by the ngModelController when the View-
        // Model has been changed by an external factor (ie, not a dropdown
        // directive interaction). When this happens, we have to update the
        // local state to reflect the ngModel state.
        function renderSelectedOptionAsync() {
            // Since the options may be rendered by a dynamically-linking
            // directive like ngRepeat or ngIf, we have to give the content
            // a chance to be rendered before we try to find a matching
            // option value.
            // --
            // Since ngModel $watch() bindings are set up in the
            // ngModelController, it means that they are bound before the DOM
            // tree is linked. This means that the ngModel $watch() bindings
            // are bound before the linking phase which puts the ngRepeat and
            // ngIf $watch() bindings at a lower priority, even when on the
            // same Scope instance, which is why we have to render asynchronously,
            // giving ngRepeat and ngIf a chance to react to $watch() callbacks.
            // The more you know!
            scope.$evalAsync(renderSelectedOption);
        }
        // ---
        // PRIVATE METHODS.
        // ---
        // I determine if the options menu is being shown.
        function isOpen() {
            return (dom.module.hasClass("dropdown-open"));
        }
        // I find rendered option with the given value. This evaluates the
        // [option] attribute in the context of the local scope and then
        // performs a direct object reference comparison.
        function findOptionWithValue(value) {
            // Since the options are dynamic, we have to collection just-in-
            // time with the selection event.
            var options = dom.options.children("li.dropdown-option");
            for (var i = 0, length = options.length ; i < length ; i++) {
                var option = angular.element(options[i]);
                if (getOptionValue(option) === value) {
                    return(option);
                }
            }
            return(null);
        }
        // I get the value of the given option (as evaluated in the context
        // of the local scope associated with the option element).
        function getOptionValue(option) {
            var accessor = $parse(option.attr("option") || "null");
            return(accessor(option.scope()));
        }
        // I hide the options menu.
        function hideOptions() {
            dom.module.removeClass("dropdown-open");
        }
        // I update the dropdown state to reflect the currently selected
        // ngModel value.
        function renderSelectedOption() {
            // Find the FIRST DOM element that matches the selected value.
            var option = findOptionWithValue( ngModelController.$viewValue );
            // Remove any current selection.
            dom.options.find("li.dropdown-option")
                .removeClass("dropdown-selection")
            ;
            // If we found a matching option, copy the content to the root.
            if (option) {
                dom.rootLabel
                    .removeClass("dropdown-placeholder")
                    .html(option.html())
                ;
                option.addClass("dropdown-selection");
            // If we have no matching option, copy the placeholder to the root.
            } else {
                dom.rootLabel
                    .addClass("dropdown-placeholder")
                    .html(placeholder)
                ;
            }
        }
        // I show the options menu.
        function showOptions() {
            dom.module.addClass("dropdown-open");
        }
    }
}]);
