/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').directive('detectsMobile', DetectsMobile);

    DetectsMobile.$inject = [
        '$timeout',
        'screen'
    ];

    ////////////////////////////////////////////////////////////////////////////

    function DetectsMobile($timeout, screen) {
        return {
            restrict: 'A',
            link: link
        };

        ////////////////////////////////////////////////////////////////////////

        function link(scope, el, attrs) {
            $(window).resize(function () {
                scope.$apply(notify(scope));
            });

            $timeout(function () {
                scope.$apply(notify(scope));
            });
        }

        function notify(scope) {
            var width = $(document).width();

            if (scope.setScreenSize) {
                scope.setScreenSize(screen.getSize(width));
            } else if (scope.$ctrl.setScreenSize) {
                scope.$ctrl.setScreenSize(screen.getSize(width));
            }
        }
    }
}());
