/*global resortModule*/
/*global angular*/
/*used on book page and confirmation page*/

(function () {
    'use strict';

    var Review = function ($stateParams, $filter, $scope, httpService, reviewService, flashService, reservationService, fancyboxService) {
        var self = this;

        self.reservation = null;
        self.reviewForm = {
            reservation_id: null,
            start_date: null,
            rating: null,
            title: "",
            body: "",
            name: "",
            location: ""
        };
        self.rate = rate;
        self.submit = submit;
        self.mobileTips = mobileTips;
        self.mobileReservationDetails = mobileReservationDetails;
        self.submitted = httpService.hasPendingRequests;
        self.reviewExists = false;
        self.reviewReady = false;
        self.reservationReady = false;
        self.documentReady = function () {
            return self.reviewReady && self.reservationReady;
        };

        function rate(rating) {
            self.reviewForm.rating = rating;
        }

        function submit() {
            reviewService.createReview(self.reviewForm).then(function (response) {
                if (response.success === true) {
                    self.reviewExists = true;
                    flashService.success(response.message);
                } else {
                    flashService.error(response.message);
                }
            });
        }

        function mobileTips() {
            fancyboxService.openURL('templates/reviews/review-tips.html', $scope);
        }

        function mobileReservationDetails() {
            fancyboxService.openURL('templates/reviews/review.reservation-details.html', $scope);
        }

        function onInit() {
            reservationService.getReservation($stateParams.token).then(function (reservation) {
                self.reservation = reservation;
                self.reviewForm.reservation_id = reservation.reservation_id;
                self.reviewForm.start_date = $filter('date')(reservation.start_date, 'MM/dd/yyyy');
                self.reviewForm.name = reservation.guest.contact.first_name;
                if (reservation.address) {
                    if (reservation.address.city) {
                        self.reviewForm.location = reservation.address.city;
                    }
                    if (reservation.address.state && reservation.address.state.name) {
                        self.reviewForm.location += ', ' + reservation.address.state.name;
                    }
                }

                self.reservationReady = true;
            });

            reviewService.getReview($stateParams.token).then(function (review) {
                self.reviewExists = review;
                self.reviewReady = true;
            });
        }

        onInit();
    };

    Review.$inject = ['$stateParams', '$filter', '$scope', 'httpService', 'reviewService', 'flashService', 'reservationService', 'fancyboxService'];

    angular.module('resortApp').controller('reviewController', Review);
}());
