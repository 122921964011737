/*global resortModule*/
//cuts expressions down to max length
//used in gooey
resortModule.filter('cut', ['$sce', function ($sce) {
    return function (value, wordwise, max, tail) {
        if (!value) {
            return '';
        }
        max = parseInt(max, 10);
        if (!max) {
            return value;
        }
        if (value.length <= max) {
            return value;
        }
        value = value.substr(0, max);
        if (wordwise) {
            var lastspace = value.lastIndexOf(' ');
            if (lastspace !== -1) {
                value = value.substr(0, lastspace);
            }
        }
        var cut = value + (tail || ' ...');
        var trust = $sce.trustAsHtml(cut);
        return trust;
    };
}]);



