//FACTORY declaration
resortModule.factory('broadcastService', ['locaService', '$rootScope', function (locaService, $rootScope) {

    //variables for factory
    var broadcastService = {};

    //UPDATE
    //gooey searchbox open
    broadcastService.updateGooeyOpen = function (gooeyOpen) {
        locaService.gooeyOpen = gooeyOpen;
        $rootScope.$broadcast("gooeyUpdated");
    };

    //header nav open (mobile)
    broadcastService.updateHeadOpen = function (mobileHeadOpen) {
        locaService.mobileHeadOpen = mobileHeadOpen;
        $rootScope.$broadcast("headingUpdated");
    };

    //used primarily for emails
    broadcastService.updateListingData = function (listingData) {
        locaService.listingData = listingData;
        $rootScope.$broadcast("listingDataUpdated");
    };

    //resort
    broadcastService.updateResort = function (resort) {
        locaService.resort = resort;
        $rootScope.$broadcast("resortUpdated");
    };

    //resort from slug
    broadcastService.updateResortFromSlug = function (resortSlug) {
        broadcastService.updateResortInfo(resortSlug);
        $rootScope.$broadcast("resortUpdated");
    };

    //related resorts
    broadcastService.updateRelatedResorts = function (relatedResorts) {
        locaService.relatedResorts = relatedResorts;
        $rootScope.$broadcast("relatedResortsUpdated");
    };

    //destination
    broadcastService.updateDestination = function (destination) {
        locaService.destination = destination;
        $rootScope.$broadcast("destinationUpdated");
    };

    //region
    broadcastService.updateRegion = function (region) {
        locaService.region = region;
        $rootScope.$broadcast("regionUpdated");
    };

    //book information
    broadcastService.updateBookInfo = function (bookInfo) {
        locaService.bookInfo = bookInfo;
        $rootScope.$broadcast("bookInfoUpdated");
    };

    //resort information
    broadcastService.updateResortInfo = function (resortInfo) {
        locaService.resortInfo = resortInfo;
        locaService.resort = resortInfo.id;
        locaService.resortSlug = resortInfo.resortSlug;
        $rootScope.$broadcast("resortInfoUpdated");
    };

    //featured information
    broadcastService.updateFeaturedInfo = function (featuredInfo) {
        locaService.featuredInfo = featuredInfo;
        $rootScope.$broadcast("featuredInfoUpdated");
    };

    //start date
    broadcastService.updateStartDate = function (startDate) {
        locaService.startDate = startDate;
        $rootScope.$broadcast("startDateUpdated");
    };

    //end date
    broadcastService.updateEndDate = function (endDate) {
        locaService.endDate = endDate;
        $rootScope.$broadcast("endDateUpdated");
    };

    //confirmation information
    broadcastService.updateConfirmationInfo = function (confirmationInfo) {
        locaService.confirmationInfo = confirmationInfo;
        $rootScope.$broadcast("confirmationInfoUpdated");
    };

    //destination name
    broadcastService.updateDestinationName = function (destinationName) {
        locaService.destinationName = destinationName;
        $rootScope.$broadcast("destinationNameUpdated");
    };

    //destination index
    broadcastService.updateDestIndex = function (destIndex) {
        locaService.destIndex = destIndex;
        $rootScope.$broadcast("destIndexUpdated");
    };

    //region index
    broadcastService.updateRegionIndex = function (regionIndex) {
        locaService.regionIndex = regionIndex;
        $rootScope.$broadcast("regionIndexUpdated");
    };

    //resort name
    broadcastService.updateResortName = function (resortName) {
        locaService.resortName = resortName;
        $rootScope.$broadcast("resortNameUpdated");
    };

    return broadcastService;
}]);
