/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').component('relatedResorts', {
        controller: RelatedResortsController,
        templateUrl: 'templates/resorts/related-resorts.component.html',
        bindings: {
            relatedResorts: '<',
            resortImagesBaseUrl: '<'
        }
    });

    RelatedResortsController.$inject = ['$timeout']

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    function RelatedResortsController($timeout) {
        var self = this;

        self.dataReady = null;
        //responsive related resorts slick slider
        self.breakpoints = [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 650,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ];

        self.$onChanges = onChanges;

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        //Toggle flag with timeout to trigger slick carousel re-init
        function onChanges(changes) {
            self.dataReady = false;
            $timeout(function () {
                self.dataReady = true;
            }, 10);
        }
    }
}());
