/* global resortModule */
/* note : on gooey post, url is redirected to resorts page, see gooey controller if this runs into conflicts */

resortModule.config(['$stateProvider', '$locationProvider', '$animateProvider',
    function ($stateProvider, $locationProvider, $animateProvider) {
        //$locationProvider passed in to keep track of $location
        //removes hash tag from url
        $locationProvider.hashPrefix('!');
        $locationProvider.html5Mode(true);
        //animations will not work unless there is an animate class applied
        $animateProvider.classNameFilter(/animate/);
    }
]);