/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').component('listings', {
        controller: ListingsController,
        bindings: {
            checkin: '<',
            checkout: '<',
            resortInfo: '<',
            roomTypes: '<',
            bookInfo: '<',
            selectedIndex: '='
        },
        templateUrl: 'templates/resorts/availability.html'
    });

    ListingsController.$inject = ['httpService', 'flashService', '$filter', 'resortImages'];

    ////////////////////////////////////////////////////////////////////////////

    function ListingsController(httpService, flashService, $filter, resortImages) {
        var self = this;

        self.sundanceSearch = false;
        self.withinSundance = false;
        self.sundanceYears = [];
        self.sundanceForm = {
            name: '',
            email: '',
            phone: '',
            studio: false,
            one_bedroom: false,
            two_bedroom: false,
            studio_quantity: '',
            one_bedroom_quantity: '',
            two_bedroom_quantity: '',
            marriotts_summit_watch: false,
            marriotts_mountainside: false,
            referred_by: '',
            message: ''
        }

        self.roomTypeWarning = false;
        self.resortWarning = false;
        self.startDateWarning = false;
        self.endDateWarning = false;
        self.sdDatesWarning = false;

        self.rentalCounts = null;
        self.roomTypeId = null;
        self.filteredInfo = [];
        self.resortImagesBaseUrl = resortImages.baseUrl;

        self.$onInit = onInit;
        self.$onChanges = onInit;
        self.sundanceSubmit = sundanceSubmit;
        self.checkDates = checkDates;
        self.isDisabled = isDisabled;
        self.checkboxChanged = checkboxChanged;
        self.hasPendingRequests = hasPendingRequests;
        self.onClickTab = onClickTab;
        self.isRentalBookingLink = isRentalBookingLink;
        self.hasRentals = hasRentals;
        self.getRentalCount = getRentalCount;
        self.setAccordionIndex = setAccordionIndex;

        ///////////////////////////
        function onInit() {
            if (self.bookInfo.sundance) {
                self.sundanceSearch = true;
                self.withinSundance = true;
                self.sundanceYears = self.bookInfo.years;

                $('#start_date').datepicker({
                    showOn: 'focus',
                    beforeShow: function() {
                         $('#ui-datepicker-div').addClass('form-datepicker');
                    },
                    onClose: self.checkDates
                });
                $('#start_date').datepicker('setDate', $filter('date')(new Date(self.checkin), 'MM/dd/yyyy', '+0'));

                $('#end_date').datepicker({
                    showOn: 'focus',
                    minDate:  $filter('date')(new Date(self.checkin), 'MM/dd/yyyy', '+0'),
                    beforeShow: function() {
                        $('#ui-datepicker-div').addClass('form-datepicker');
                    },
                    onClose: self.checkDates
                });
                $('#end_date').datepicker('setDate', $filter('date')(new Date(self.checkout), 'MM/dd/yyyy', '+0'));

            } else {
                self.sundanceSearch = false;
                self.rentalCounts = findRentalCounts(self.bookInfo);

                var idxs = findFirstAvailableRoomType(self.roomTypes, self.rentalCounts);
                if (idxs !== null) {
                    self.selectedIndex = idxs[0];
                    self.roomTypeId = idxs[1];
                }

                self.filteredInfo = findFilteredListings(self.bookInfo, self.roomTypeId);
            }
        }

        function sundanceSubmit() {
            var submitForm = {
                name: self.sundanceForm.name,
                email: self.sundanceForm.email,
                phone: self.sundanceForm.phone,
                studio_quantity: self.sundanceForm.studio ? self.sundanceForm.studio_quantity : 0,
                one_bedroom_quantity: self.sundanceForm.one_bedroom ? self.sundanceForm.one_bedroom_quantity : 0,
                two_bedroom_quantity: self.sundanceForm.two_bedroom ? self.sundanceForm.two_bedroom_quantity : 0,
                marriotts_summit_watch: self.sundanceForm.marriotts_summit_watch,
                marriotts_mountainside: self.sundanceForm.marriotts_mountainside,
                start_date: $filter('date')($('#start_date').datepicker('getDate'), 'yyyy-MM-dd'),
                end_date: $filter('date')($('#end_date').datepicker('getDate'), 'yyyy-MM-dd'),
                referred_by: self.sundanceForm.referred_by,
                message: self.sundanceForm.message
            }

            var roomTypeGroup = [
                self.sundanceForm.studio,
                self.sundanceForm.one_bedroom,
                self.sundanceForm.two_bedroom
            ];
            var resortGroup = [
                submitForm.marriotts_mountainside,
                submitForm.marriotts_summit_watch
            ];
            self.roomTypeWarning = !checkRequiredGroup(roomTypeGroup);
            self.resortWarning = !checkRequiredGroup(resortGroup);
            self.startDateWarning = !submitForm.start_date;
            self.endDateWarning = !submitForm.end_date;
            self.sdDatesWarning = !self.withinSundance;

            if (self.roomTypeWarning || self.resortWarning || self.startDateWarning || self.endDateWarning || self.sdDatesWarning) {
                return null;
            }

            httpService.sundanceInquiry(submitForm)
                .then(
                    function (result) {
                        if (result.data.success) {
                            $('#start_date').datepicker('setDate', $filter('date')(new Date(self.checkin), 'MM/dd/yyyy', '+0'));
                            $('#end_date').datepicker('setDate', $filter('date')(new Date(self.checkout), 'MM/dd/yyyy', '+0'));
                            self.sundanceForm = {
                                name: '',
                                email: '',
                                phone: '',
                                studio: false,
                                one_bedroom: false,
                                two_bedroom: false,
                                studio_quantity: '',
                                one_bedroom_quantity: '',
                                two_bedroom_quantity: '',
                                marriotts_summit_watch: false,
                                marriotts_mountainside: false,
                                referred_by: '',
                                message: ''
                            };
                            flashService.success();
                        } else {
                            flashService.error(result.data.message);
                        }
                    },
                    function (result) {
                        flashService.error(result.data.message);
                    }
                );
        }

        function checkDates(dateText, datepicker) {
            var startDate = $filter('date')($('#start_date').datepicker('getDate'), 'yyyy-MM-dd');
            var endDate = $filter('date')($('#end_date').datepicker('getDate'), 'yyyy-MM-dd');
            self.withinSundance = false;

            self.sundanceYears.forEach(function (year) {
                if (!(startDate > year.end_date || endDate < year.start_date)) {
                    self.withinSundance = true;
                }
            });

            if (!self.withinSundance) {
                flashService.error('You\'ve entered dates that fall outside of the Sundance festival. Please retry your search for accurate results.');
            }

            if (datepicker.id === 'start_date') {
                $('#end_date').datepicker('option', 'minDate', dateText);
            }
        }

        function isDisabled(roomType) {
            return self.sundanceForm[roomType] ? '#eeeeed' : '#fff';
        }

        function checkRequiredGroup(group) {
            var filtered = group.filter(function(item) {
                return item === true
            })

            return filtered.length > 0
        }

        function checkboxChanged(model, roomType) {
            if (!model) {
                switch (roomType) {
                    case 0:
                        self.sundanceForm.studio_quantity = '';
                        break;
                    case 1:
                        self.sundanceForm.one_bedroom_quantity = '';
                        break;
                    case 2:
                        self.sundanceForm.two_bedroom_quantity = '';
                }
            }
        }

        function hasPendingRequests() {
            return httpService.hasPendingRequests();
        }

        function onClickTab(idx, roomTypeId) {
            self.selectedIndex = +idx;
            self.roomTypeId = +roomTypeId;
            self.filteredInfo = findFilteredListings(self.bookInfo, self.roomTypeId);
        }

        function isRentalBookingLink(listing) {
            return listing
                && +listing.ind === 1
                && +listing.rental_id > 0;
        }

        function hasRentals(roomTypeId) {
            roomTypeId = +roomTypeId;
            return self.rentalCounts[roomTypeId] && self.rentalCounts[roomTypeId] > 0;
        }

        function getRentalCount(roomTypeId) {
            roomTypeId = +roomTypeId;
            return self.rentalCounts[roomTypeId] ? self.rentalCounts[roomTypeId] : 0;
        }

        function setAccordionIndex(roomType, index) {
            roomType.open = self.selectedIndex === index;
        }

        function findRentalCounts(listings) {
            var rentalCounts = {};
            for (var i = 0, len = listings.length; i < len; i++) {
                // cast to int
                listings[i].room_type_id = +listings[i].room_type_id;

                // count or initialize
                if (rentalCounts[listings[i].room_type_id]) {
                    rentalCounts[listings[i].room_type_id] += 1;
                } else {
                    rentalCounts[listings[i].room_type_id] = 1;
                }
            }
            return rentalCounts;
        }

        function findFirstAvailableRoomType(roomTypes, rentalCounts) {
            for (var i = 0, len = roomTypes.length ; i < len; i ++) {
                var roomTypeId = +roomTypes[i].id;
                if (rentalCounts[roomTypeId] && rentalCounts[roomTypeId] > 0) {
                    return [i, roomTypeId];
                }
            }
            return null;
        }

        function findFilteredListings(listings, roomTypeId) {
            var filtered = [];
            for (var i = 0, len = listings.length; i < len; i++) {
                if (+listings[i].room_type_id === roomTypeId) {
                    filtered.push(listings[i]);
                }
            }
            return filtered;
        }
    }
}());
