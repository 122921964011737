/*global resortModule*/
/*global angular*/
/*used on book page and confirmation page*/

(function () {
    'use strict';

    angular.module('resortApp').controller('testimonialCreateController', TestimonialCreate);

    TestimonialCreate.$inject = ['testimonialService', 'flashService'];

    ////////////////////////////////////////////////////////////////////////////

    function TestimonialCreate(testimonialService, flashService) {

        var self = this;

        // Default values for testimonial form
        self.testimonialForm = {
            rating: null,
            title: "",
            body: "",
            start_date: null,
            name: "",
            location: ""
        };

        self.submit = function () {
            testimonialService.createTestimonial(self.testimonialForm).then(function (response) {
                if (response.success === true) {
                    flashService.success(response.message);
                } else {
                    flashService.error(response.message);
                }
            });
        };
    }
}());
