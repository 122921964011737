/*global resortModule*/
resortModule.directive('vcLogo', function () {
    return {
        restrict: 'E',
        templateUrl: 'templates/svg/vc-logo.html'
    };
});
resortModule.directive('vcLogoSmall', function () {
    return {
        restrict: 'E',
        templateUrl: 'templates/svg/vc-logo-small.html'
    };
});
