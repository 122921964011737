/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').directive('starRating', Rating);

    ////////////////////////////////////////////////////////////////////////////

    function Rating() {

        var fullStar = '<i class="fa fa-star"></i>';
        var openStar = '<i class="fa fa-star-o"></i>';
        var maxStars = 5;
        var containerClass = 'stars-container';

        return {
            restrict: 'E',
            template: '<div class="' + containerClass + '"></div>',
            scope: {
                stars: '='
            },
            link: link
        };

        ////////////////////////////////////////////////////////////////////////

        function link(scope, el) {
            var numStars = parseInt(scope.stars);
            var container = $('.' + containerClass, el);
            var fullStars = 0;

            for (var i = 0; i < numStars; i++) {
                fullStars += 1;
                container.append(fullStar);
            }

            while (fullStars < maxStars) {
                fullStars += 1;
                container.append(openStar);
            }
        }
    }
}());
