resortModule.directive('matchWindowHeight', ['$filter', '$timeout', '$window', function ($filter, $timeout, $window) {
    return {
        link: function(scope, el, attrs, ctrl) {
            var windowHeight,
                setHeight,
                innerDivHeight,
                innerDivWidth,
                divException;
            var innerDiv = el.find($('.inner-div'));
            var headerHeight = scope.$ctrl.isMobile ? 111 : 149;

            function set () {
                windowHeight = $(window).height();
                setHeight = windowHeight - headerHeight;
                innerDivHeight = -(innerDiv.height()/2);
                innerDivWidth = -(innerDiv.innerWidth()/2);

                if (setHeight > innerDivHeight) {
                    el.css('height', setHeight);
                    el.find(innerDiv).css('margin-left', innerDivWidth);
                    el.find(innerDiv).css('margin-top', innerDivHeight);
                }
            }
            set();

            angular.element($window).bind('resize', function(){
                set();
            });
        }
    };
}]);
