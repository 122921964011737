/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').component('averageReviews', {
        templateUrl: 'templates/reviews/average-reviews.component.html',
        controller: AverageReviewsController,
        bindings: {
            reviews: '<',
            onClick: '<'
        }
    });

    ////////////////////////////////////////////////////////////////////////////

    function AverageReviewsController() {
        var self = this;
        
        self.reviewCount = 0;
        self.average = null;

        self.$onInit = onInit;
        self.$onChanges = onInit;
        self.hasReviews = hasReviews;

        ////////////////////////////////////////////////////////////////////////

        function onInit() {
            var total = 0;

            self.reviewCount = self.reviews.length;
            self.reviews.forEach(function (review) {
                total += review.rating;
            });

            self.average = Math.round((total / self.reviewCount) * 2) / 2;
        }

        function hasReviews() {
            return self.reviews && self.reviews.length > 0;
        }
    }
}());