/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').component('stars', {
        templateUrl: 'templates/reviews/stars.component.html',
        controller: StarsController,
        bindings: {
            stars: '<'
        }
    });

    ////////////////////////////////////////////////////////////////////////////

    function StarsController() {
        var self = this;
        
        self.full = [];
        self.half = [];
        self.empty = [];

        self.$onInit = onInit
        self.$onChanges = onInit;

        ////////////////////////////////////////////////////////////////////////

        function onInit() {
            self.full = [];
            self.half = [];
            self.empty = [];

            var starsFloor = Math.floor(self.stars);
            var starsDecimal = self.stars - starsFloor;

            for (var i = 0; i < starsFloor; i++) {
                self.full.push(i);
            }

            if (starsDecimal > 0.4) {
                self.half.push(i);
                i += 1;
            }

            for (i; i < 5; i++) {
                self.empty.push(i);
            }
        }
    }
}());