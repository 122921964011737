/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').component('faq', {
        controller: FAQController,
        bindings: {
            notices: '<',
            faqs: '<'
        },
        templateUrl: 'templates/resorts/faqs.html'
    });

    FAQController.$inject = [
        '$sce', 'fancyboxService'
    ];

    ////////////////////////////////////////////////////////////////////////////

    function FAQController ($sce, fancyboxService) {
        var self = this;

        self.maxHeight = 385;
        self.faqLimit = 3;

        self.$onInit = onInit;
        self.$onChanges = onInit;
        self.canReadMoreFaq = canReadMoreFaq;
        self.displaySpecial = displaySpecial;

        ////////////////////////////////////////////////////////////////////////

        function onInit() {
            if (self.notices && self.notices.length > 0) {
                self.notices.forEach(function (notice) {
                    notice.value = notice.value.replace(/\n/g, '<br />');
                });
            }

            var noticeHeight = $('.notice-faq .resort-notices').outerHeight(),
                faqHeight = $('.notice-faq .faqs').outerHeight();

            if (noticeHeight + faqHeight > self.maxHeight) {
                if (!$('.notice-faq').hasClass('notice-overflow')) {
                    $('.notice-faq').addClass('notice-overflow');
                }
            } else {
                if ($('.notice-faq').hasClass('notice-overflow')) {
                    $('.notice-faq').removeClass('notice-overflow');
                }
            }
        }

        function canReadMoreFaq(description) {
            return description && description.length >= 500;
        }

        function displaySpecial(html) {
            return $sce.trustAsHtml(html);
        }
    }
}());
