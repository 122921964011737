/*global resortModule*/

(function () {
    'use strict';

    var Kayak = function ($rootScope, rerouteService, $filter, logicService, $q, $location, broadcastService, paramService, $state) {
        var self = this;

        self.appDestination = $rootScope.Global.destination;
        self.resorts = self.appDestination.resorts;
        self.roomTypes = self.appDestination.room_types;
        self.lowest = self.appDestination.lowest;
        self.selectedIndex = Object.keys(self.roomTypes)[0];
        self.activeIndex = 0;
        self.endDate = $location.search().checkout;

        self.hasLowestListing = function (resortId) {
            resortId = parseInt(resortId, 10);

            return self.lowest[resortId] && true;
        };

        function nightlyPrice (price, nights) {
            if (nights > 0 && price > 0) {
                return price / nights;
            }
            return false;
        }

        //offers first resort listing price as lowest price on resort card
        self.lowestListingPrice = function (resortId) {
            resortId = parseInt(resortId, 10);

            if (!resortId || !self.hasLowestListing(resortId)) {
                return "Price Not Available";
            }

            var price = parseFloat(self.lowest[resortId].price);
            var nights = parseInt(self.lowest[resortId].nights, 10);
            var finalPrice = nightlyPrice(price, nights);

            if (finalPrice) {
                return $filter('currency')(finalPrice);
            }
            return "Price Not Available";
        };

        //offers first resort listing price as lowest price on resort card
        self.lowestListingRoomName = function (resortId) {
            resortId = parseInt(resortId, 10);

            if (!resortId || !self.hasLowestListing(resortId)) {
                return "";
            }

            var rooms = parseInt(self.lowest[resortId].rooms, 10);

            if (rooms === 0) {
                return "Studios";
            }

            return  rooms + " Bedrooms";
        };

        //adds active class to selected tabs and filters listing data
        self.onClickTab = function (idx, room_type_index) {
            self.selectedIndex = room_type_index;
            self.activeIndex = idx
        };

        //reroute when resort card is clicked on
        self.viewResort = function (resort) {
            var dest = self.appDestination.id, region = self.appDestination.region_id;
            if (self.startDate && self.endDate && resort) {
                logicService.newPost(resort.id, self.startDate, self.endDate);
            } else if (resort) {
                //updates resort in service, which in turn updates it in all other controllers
                logicService.newResort(resort.id, true);
                rerouteService.changeDateLessResorts(resort.slug);
            }
            if (dest && region) {
                logicService.getRegionDestInfo(region, dest);
            }
        };

        self.goToRental = function (id, room_type) {
            var hash = paramService.decode(room_type);

             var args = {
                listingId: id,
                '#': hash,
            };


            $state.transitionTo("app.resorts.listing", args);
        };

        //responsive slick slider
        self.breakpoints = [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 650,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ];
    }
    //dependency injection
    Kayak.$inject = ['$rootScope', 'rerouteService', '$filter', 'logicService', '$q', '$location', 'broadcastService', 'paramService', '$state'];

    //controller declaration
    angular.module('resortApp').controller('kayakController', Kayak);
}());
