/* highlight date ranges */
resortModule.directive('highlight', ['$window', '$timeout', function($window, $timeout) {
    return {
        restrict: 'A',
        link: function(scope, elm, attrs) {
            var windowWidth;
            function ifCi () {
                var childEl;
                var endEl;
                if (windowWidth > 1023) {
                    childEl = $(".ci .ui-datepicker-current-day").last();
                    endEl = $('.highlight_end').last();
                } else {
                    childEl = $(".ci .ui-datepicker-current-day").first();
                    endEl = $('.highlight_end').first();
                }
                //add block classes
                childEl.parents('.ui-datepicker-group').addClass('beginBlock');
                endEl.parents('.ui-datepicker-group').addClass('finalBlock');
                //add row classes
                childEl.parent().addClass('beginRow');
                endEl.parent().addClass('finalRow');

                //highlights last rows from the two blocks
                if (!$('.beginRow').hasClass('finalRow')) {
                    //highlights tds on row previous to date
                    endEl.prevAll().not(".ui-datepicker-unselectable").not(".ui-datepicker-current-day").addClass('highlight');
                    //highlights tds on row after date
                    childEl.last().nextAll().not(".ui-datepicker-unselectable").not(".ui-datepicker-current-day").addClass('highlight');
                    $('.beginRow').nextUntil('.finalRow').find('td:not(.ui-datepicker-unselectable):not(.ui-datepicker-current-day)').addClass('highlight');
                    $('.finalRow').prevUntil('.beginRow').find('td:not(.ui-datepicker-unselectable):not(.ui-datepicker-current-day)').addClass('highlight');
                } else {
                    childEl.last().nextUntil('td.highlight_end').addClass('highlight');
                }

                //highlights in between blocks
                if (!($('.beginBlock').hasClass('finalBlock'))) {
                    $('.beginBlock').nextUntil('.finalBlock').find('td:not(.ui-datepicker-unselectable):not(.ui-datepicker-current-day)').addClass('highlight');
                    $('.finalBlock').prevUntil('.beginBlock').find('td:not(.ui-datepicker-unselectable):not(.ui-datepicker-current-day)').addClass('highlight');
                }
            }
            function ifCo () {
                var childEl;
                if (windowWidth > 1023) {
                    childEl = $(".co .ui-datepicker-current-day").not('.highlight_begin').last();
                } else {
                    childEl = $(".co .ui-datepicker-current-day").not('.highlight_begin').first();
                }
                childEl.parents('.ui-datepicker-group').addClass('finalBlock');
                childEl.parent().addClass('finalRow');
                //highlights any tds that come the month before
                $('.finalBlock').prevAll().find('td:not(.ui-datepicker-unselectable):not(.ui-datepicker-current-day)').addClass('highlight');
                //highlight tds before final row
                $('.finalRow').prevAll().find('td:not(.ui-datepicker-unselectable):not(.ui-datepicker-current-day)').addClass('highlight');
                //highlight row before selected date
                childEl.prevAll('td:not(.ui-datepicker-unselectable):not(.ui-datepicker-current-day)').addClass('highlight');
            }
            function reHighlight () {
                if (scope.ci && scope.co && (scope.checkoutSelect || scope.checkinSelect)) {
                    $(elm).find('.highlight').removeClass('highlight');
                    if (scope.checkinSelect) {
                        ifCi();
                    } else {
                        ifCo();
                    }
                }
            }
            elm.on('click', function($event) {
                reHighlight();
            });
            scope.$watch('checkoutSelect', function() {
                if (scope.checkoutSelect) {
                    $timeout(function () {
                        reHighlight();
                    });
                }
            });
            scope.$watch('checkinSelect', function() {
                if (scope.checkinSelect) {
                    reHighlight();
                }
            });
            scope.$watch(function(){
               return $window.innerWidth;
            }, function(value) {
                windowWidth = value;
           });
        }
    };
}]);