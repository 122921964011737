/*global angular resortModule, $scope, event, window*/
(function () {
    'use strict';

    angular.module('resortApp').component('searchBox', {
        templateUrl: ['$element', '$attrs', function ($element, $attrs) {
            return 'templates/search-box/' + $attrs.template + '.html';
        }],
        controller: SearchBoxController,
        bindings: {
            'resortId': '<',
            'appCheckin': '<',
            'appCheckout': '<'
        }
    });

    SearchBoxController.$inject = ['$rootScope', '$state', '$filter', 'regions', 'flashService'];

    ////////////////////////////////////////////////////////////////////////////////

    function SearchBoxController($rootScope, $state, $filter, regions, flashService) {
        var self = this;

        self.regions = regions;

        self.checkin = null;
        self.checkout = null;
        self.region = null;
        self.destination = null;
        self.resort = null;
        self.searchRegion = null;
        self.searchDestination = null;
        self.searchResort = null;

        self.isOpen = false;
        self.fixed = false;

        self.selectingCheckin = false;
        self.selectingCheckout = false;
        self.selectingRegion = false;
        self.selectingDestination = false;
        self.selectingResort = false;

        self.beginSelection = beginSelection;
        self.arrowClick = arrowClick;

        self.showBegin = showBegin;
        self.showCheckin = showCheckin;
        self.showCheckout = showCheckout;
        self.showRegion = showRegion;
        self.showDestination = showDestination;
        self.showResort = showResort;
        self.showArrow = showArrow;

        self.selectedCheckin = selectedCheckin;
        self.selectedCheckout = selectedCheckout;
        self.selectedRegion = selectedRegion;
        self.selectedDestination = selectedDestination;
        self.selectedResort = selectedResort;

        self.selectedCheckinValue = selectedCheckinValue;
        self.selectedCheckoutValue = selectedCheckoutValue;
        self.selectedRegionValue = selectedRegionValue;
        self.selectedDestinationValue = selectedDestinationValue;
        self.selectedResortValue = selectedResortValue;

        self.nextClickCheckin = nextClickCheckin;
        self.nextClickCheckout = nextClickCheckout;
        self.showCheck = showCheck;
        self.mobileShowCheckin = mobileShowCheckin;
        self.mobileShowCheckout = mobileShowCheckout;
        self.cancelSearch = cancelSearch;
        self.toggleOpen = toggleOpen;
        self.search = search;
        self.resortCut = resortCut;

        self.$onInit = onInit;
        self.$onChanges = onInit;

        ////////////////////////////////////////////////////////////////////////////

        $rootScope.$on('openSearchBox', function () {
            self.selectedCheckin();
        });

        $rootScope.$on('closeSearchBox', function () {
            self.cancelSearch();
        });

        function onInit() {
            if (self.appCheckin && self.appCheckout) {
                var checkinTimeZoneDate = new Date(self.appCheckin);
                var checkoutTimeZoneDate = new Date(self.appCheckout);
                self.checkin = new Date(checkinTimeZoneDate.getUTCFullYear(), checkinTimeZoneDate.getUTCMonth(), checkinTimeZoneDate.getUTCDate());
                self.checkout = new Date(checkoutTimeZoneDate.getUTCFullYear(), checkoutTimeZoneDate.getUTCMonth(), checkoutTimeZoneDate.getUTCDate());
            }

            if ($state.params.startDate && !self.checkin) {
                var timeZoneDate = new Date($state.params.startDate);
                self.checkin = new Date(timeZoneDate.getUTCFullYear(), timeZoneDate.getUTCMonth(), timeZoneDate.getUTCDate());
            }
            if ($state.params.endDate && !self.checkout) {
                var timeZoneDate = new Date($state.params.endDate);
                self.checkout = new Date(timeZoneDate.getUTCFullYear(), timeZoneDate.getUTCMonth(), timeZoneDate.getUTCDate());
            }

            if (self.resortId !== null) {
                filterByResortId(self.resortId);
            }

            self.searchRegion = self.region;
            self.searchResort = self.resort;
            self.searchDestination = self.destination;
        }

        function filterByResortId(resortId) {
            regions.filter(function (regionVals) {
                regionVals.destinations.filter(function (destinationVals) {
                    destinationVals.resorts.filter(function (resortVals) {
                        if (resortVals.id == resortId) {
                            self.resort = resortVals;
                            self.destination = destinationVals;
                            self.region = regionVals;
                        }
                    });
                });
            });
        }

        function beginSelection() {
            self.isOpen = true;

            closeAll();
            self.selectingCheckin = true;
        }

        function arrowClick() {
            closeAll();
            self.search();
        }

        function showBegin() {
            return isEmpty() && !self.selectingCheckin;
        }

        function showCheckin() {
            return self.selectingCheckin || self.checkin || self.region;
        }

        function showCheckout() {
            return self.selectingCheckout || self.checkin || self.checkout;
        }

        function showRegion() {
            return self.selectingRegion || self.region || self.checkout;
        }

        function showDestination() {
            return self.selectingDestination || self.destination || self.region;
        }

        function showResort() {
            return self.selectingResort || self.resort || self.destination;
        }

        function showArrow() {
            return self.resort;
        }

        function selectedCheckin() {
            if (self.selectingCheckin) {
                self.isOpen = false;
                closeAll();
            } else {
                closeAll();
                self.isOpen = true;
                self.selectingCheckin = true;
            }
        }

        function selectedCheckout() {
            if (self.selectingCheckout) {
                self.isOpen = false;
                closeAll();
            } else {
                closeAll();
                self.isOpen = true;
                self.selectingCheckout = true;
            }
        }

        function selectedRegion() {
            if (self.selectingRegion) {
                self.isOpen = false;
                closeAll();
            } else {
                closeAll();
                self.isOpen = true;
                self.selectingRegion = true;
                self.region = null;
                self.destination = null;
                self.resort = null;
            }
        }

        function selectedDestination() {
            if (self.selectingDestination) {
                self.isOpen = false;
                closeAll();
            } else {
                closeAll();
                self.isOpen = true;
                self.selectingDestination = true;
                self.destination = null;
                self.resort = null;
            }
        }

        function selectedResort() {
            if (self.selectingResort) {
                self.isOpen = false;
                closeAll();
            } else {
                closeAll();
                self.isOpen = true;
                self.selectingResort = true;
                self.resort = null;
            }
        }

        function selectedCheckinValue(checkin) {
            self.checkin = checkin;

            closeAll();

            if (self.checkout === null) {
                self.selectingCheckout = true;
            } else if (self.checkin > self.checkout) {
                self.checkout = null;
                self.selectingCheckout = true;
            } else if (self.region === null) {
                self.selectingRegion = true;
            } else if (self.destination === null) {
                self.selectingDestination = true;
            } else if (self.resort === null) {
                self.selectingResort = true;
            } else {
                self.search();
            }
        }

        function selectedCheckoutValue(checkout) {
            self.checkout = checkout;

            closeAll();

            if (self.region === null) {
                self.selectingRegion = true;
            } else if (self.destination === null) {
                self.selectingDestination = true;
            } else if (self.resort === null) {
                self.selectingResort = true;
            } else {
                self.search();
            }
        }

        function selectedRegionValue(region) {
            self.region = region;

            if (self.searchResort === null) {
                self.searchRegion = self.region;
                self.searchDestination = null;
            }

            self.destination = null;
            self.resort = null;

            closeAll();
            self.selectingDestination = true;
        }

        function selectedDestinationValue(destination) {
            self.destination = destination;

            if (self.searchResort === null) {
                self.searchDestination = self.destination;
            }

            self.resort = null;

            closeAll();
            self.selectingResort = true;
        }

        function selectedResortValue(resort) {
            self.resort = resort;

            if (self.searchResort === null) {
                self.searchResort = self.resort;
            }

            closeAll();
            self.search();
        }

        function nextClickCheckin() {
            self.selectingCheckin = false;
            self.selectingCheckout = true;
        }

        function nextClickCheckout() {
            self.selectingCheckout = false;
            self.selectingRegion = true;
        }

        function showCheck(location) {
            return (self.searchRegion === location) ||
                    (self.searchDestination === location) ||
                    (self.searchResort === location);
        }

        function mobileShowCheckin() {
            return self.checkin && !self.selectingCheckin;
        }

        function mobileShowCheckout() {
            return self.checkout && !self.selectingCheckout;
        }

        function cancelSearch() {
            closeAll();
            self.isOpen = false;
            self.region = self.searchRegion;
            self.destination = self.searchDestination;
            self.resort = self.searchResort;
        }

        function toggleOpen() {
            if (self.isOpen) {
                self.cancelSearch();
            } else {
                self.isOpen = true;
            }
        }

        function search() {
            if (!self.checkout) {
                closeAll();
                self.isOpen = true;
                self.selectingCheckout = true;
                flashService.error('A check-out date must be selected in order to return accurate results.');
            } else {
                flashService.dismiss();
                self.searchRegion = self.region;
                self.searchDestination = self.destination;
                self.searchResort = self.resort;
                var startDate = $filter('date')(self.checkin, 'yyyy-MM-dd');
                var endDate = $filter('date')(self.checkout, 'yyyy-MM-dd');
                var slug = self.resort.slug;

                self.isOpen = false;

                fbq('track', 'Search', {search_string: startDate + ' ' + endDate + ' ' + slug});

                $state.go('app.resorts.slug-dates', {
                        slug: slug,
                        startDate: startDate,
                        endDate: endDate
                })
            }
        }

        function resortCut() {
            return self.fixed ? 20 : 30;
        }

        ////////////////////////////////////////////////////////////////////////////

        function isEmpty() {
            return !self.checkin &&
                !self.checkout &&
                !self.region &&
                !self.destination &&
                !self.resort;
        }

        function closeAll() {
            self.selectingCheckin = false;
            self.selectingCheckout = false;
            self.selectingRegion = false;
            self.selectingDestination = false;
            self.selectingResort = false;
        }
    }
}());
