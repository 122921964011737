/*global resortModule*/
resortModule.run(['$rootScope', function ($rootScope) {
    $rootScope.Debug = false;

    $rootScope.Global = VCAppData;

    $rootScope.HasGlobal = function () {
        return $rootScope.isNotEmpty($rootScope.Global);
    };

    $rootScope.sprintf = function (format, args) {
        return format.replace(/{(\d+)}/g, function(match, number) {
            return typeof args[number] !== 'undefined' ? args[number] : match;
        });
    };

    var apiPrefix = '/api/';
    var apiRoutes = {
        book: 'reservations/book',
        bdl: 'reservations/book/{0}',
        ind: 'reservations/book/rental/{0}',
        bookConfirmation: 'reservations/book/confirmation',

        payment: 'payment',
        paymentLogin: 'payment/validate',

        newsletter: 'newsletters',
        contactUs: 'contact',
        review: 'review',
        termsConfirmation: 'terms/confirmation',

        resort: 'resorts/{0}',
        resortSlug: 'resorts/slug/{0}',
        relatedResorts: 'resorts/{0}/related',
        resorts: 'resorts',

        listing: 'listings/{0}',
        listingInd: 'listings/{0}/rental/{1}',
        searchDateless: 'listings/resort/{0}',
        search: 'listings/resort/{0}/startDate/{1}/endDate/{2}',

        locations: 'locations',

        sundance: 'sundance'
    };

    $rootScope.Api = function (endPoint, args) {
        var url = apiPrefix + apiRoutes[endPoint];

        if (typeof args === 'undefined' || args === null) {
            return url;
        }

        if (!angular.isArray(args)) {
            args = [args];
        }

        return $rootScope.sprintf(url, args);
    };

    $rootScope.isEmpty = function (value) {
        if (angular.isUndefined(value)) {
            return true;
        }
        if (angular.isObject(value)) {
            return Object.keys(value).length === 0;
        }
        if (angular.isArray(value)) {
            return value.length === 0;
        }
        if (angular.isString(value)) {
            return value === '';
        }
        if (angular.isNumber(value)) {
            return value === 0;
        }
        if (typeof value === 'boolean') {
            return value === false;
        }

        return value === null;
    };

    $rootScope.isNotEmpty = function (value) {
        return !$rootScope.isEmpty(value);
    };
}]);
