/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').component('header', {
        templateUrl: 'templates/header/solid.html',
        controller: HeaderController
    });

    HeaderController.$inject = ['screen'];

    ////////////////////////////////////////////////////////////////////////////

    function HeaderController(screen) {
        var self = this;

        self.isMobile = false;
        self.ready = false;

        self.setScreenSize = setScreenSize;

        ////////////////////////////////////////////////////////////////////////

        function setScreenSize(size) {
            self.isMobile = size < screen.sizes.md;
            self.ready = true;
        }
    }
}());
