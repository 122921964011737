//FACTORY declaration
resortModule.factory('locaService', ['$rootScope', function ($rootScope) {

    //variables for factory
    var locaService = {
        VCData: $rootScope.Global.regions,
        bookInfo: [], //book listings
        resortInfo: [], //information specific to resort such as name, latitude, etc
        featuredInfo: [], //featured information for specific listing on resorts page
        destination: null, //destination object
        destinationName: null, //stored destination name
        destIndex: null,    //indexed to find region
        region: null, //region object
        regionIndex: null, //indexed to find destination
        resort: null, //resort id
        resorts: [], //resorts object requested by sitemap
        resortName: null, //stored resort name
        resortSlug: null, //stored resort slug for url
        startDate: null, //stored as yyyy/mm/dd
        endDate: null, //stored as yyyy/mm/dd
        relatedResorts: [], //alternate resorts/ related resorts
        resortRating: '', //resort rating for resort reviews
        gooeyOpen: false, //for hiding and showing mobile gooey
        mobileHeadOpen: false, //for hiding and showing mobile heading
        confirmationInfo: {}, //booking confirmation
        paymentInfo: {}, //payment information for payment page
    };

    return locaService;
}]);
