/*global resortModule*/
/*global angular*/
/*used on book page and confirmation page*/

(function () {
    'use strict';

    angular.module('resortApp').component('reviewContainer', {
        templateUrl: 'templates/reviews/review-container.component.html',
        bindings: {
            reservation: '<'
        }
    });
}());
