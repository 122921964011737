resortModule.directive('blurToCurrency', ['$filter', '$timeout', function ($filter, $timeout) {
    return {
        scope: {
            amount  : '='
        },
        link: function(scope, el, attrs, ctrl) {
            function reformat () {
                var val = scope.amount;
                if (typeof val === "string") {
                    val = parseFloat(val.replace(/,/g, "").replace(/\$/g, ''));
                    if (val) {
                        scope.amount = val;
                        scope.$apply();
                    }
                }
                el.val($filter('currency')(val));
            }

            $timeout(function () {
                reformat();
            });

            el.bind('focus', function () {
                el.val(scope.amount);
            });
            el.bind('input', function () {
                scope.amount = el.val();
                scope.$apply();
            });

            el.bind('blur', function () {
                reformat();
            });
        }
    };
}]);