/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').component('covidNotices', {
        templateUrl: 'templates/covid-notices.html',
        controller: CovidNoticesController,
    });

    CovidNoticesController.$inject = [
        'covidNoticesService'
    ];

    ////////////////////////////////////////////////////////////////////////////

    function CovidNoticesController(covidNoticesService) {
        var self = this;

        self.notices = [];

        self.$onInit = onInit;

    ////////////////////////////////////////////////////////////////////////

        function onInit() {
            covidNoticesService.getNotices().then(function (notices) {
                self.notices = notices;
            });
        }
    }
}());
