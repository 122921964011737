/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').factory('listingService', ListingService);

    ListingService.$inject = ['http'];

    ////////////////////////////////////////////////////////////////////////////

    function ListingService(http) {
        var base = '/api',
            listingBase = base + '/listings';

        var lead = null;
        var listings = null;

        return {
            lead: lead,
            listings: listings,
            fetchLead: fetchLead,
            fetchListings: fetchListings,
            fetchBDL: fetchBDL
        };

        ////////////////////////////////////////////////////////////////////////

        function fetchLead(listingId, rentalId) {
            var rel = rentalId
                ? listingId + '/rental/' + rentalId + '/book'
                : listingId + '/book';

            return http.get(listingBase + '/' + rel).then(
                function (result) {
                    lead = result.data;
                    return lead;
                }
            );
        }

        function fetchListings(resortId, checkin, checkout) {
            var queryString = listingBase + '/search?resort=' + resortId;
            if (checkin && checkout) {
                queryString += '&checkin=' + checkin + '&checkout=' + checkout;
            }

            return http.get(queryString).then(function (result) {
                listings = result.data;
                return listings;
            });
        }

        function fetchBDL(stackId, timestamp) {
            return http.get(listingBase + '/' + stackId + '/' + timestamp + '/book').then(
                function (result) {
                    lead = result.data;
                    return lead;
                }
            );
        }
    }
}());
