/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').component('lead', {
        controller: LeadController,
        bindings: {
            featuredInfo: '<'
        },
        templateUrl: 'templates/resorts/lead.html'
    });

    LeadController.$inject = [
        '$rootScope'
    ];

    ////////////////////////////////////////////////////////////////////////////

    function LeadController($rootScope) {
        var self = this;

        self.isRentalBookingLink = function (listing) {
            return listing && parseInt(listing.ind, 10) === 1 && $rootScope.isNotEmpty(listing.rental_id);
        };

    }
}());
