//FACTORY declaration
resortModule.factory('findService', ['locaService', '$rootScope', function (locaService, $rootScope) {

    //variables for factory
    var findService = {};

    //finds region index information
    findService.findRegIndex = function (reg, regions) {
        reg = parseInt(reg, 10);
        for (var i = 0; i < regions.length; i++) {
            if (regions[i].id === reg) {
                return i;
            }
        }
    };

    //finds destination information
    findService.findDest = function (dest, region) {
        dest = parseInt(dest, 10);
        var destObj = region.destinations;
        //find destination object
        for (var index = 0; index < destObj.length; index ++) {
            if (destObj[index].id === dest) {
                destination = destObj[index];
                //add index to object for use in gooey
                destination.index = index;
                return destination;

            }
        }
    };

    //submit ids for destination and region for gooey display
    findService.findRegDest = function (reg, dest) {
        var regObj = $rootScope.Global.regions,
            region = [],
            destination = [];
        //find region object
        var rIndex = findService.findRegIndex(reg, regObj);
        region = regObj[rIndex];
        //add index to object for use in gooey
        region.index = rIndex;
        destination = findService.findDest(dest, region);
        return [region, destination];
    };

    //finds resort name
    findService.findResortName = function (resortId) {
        var resortName = null;
        var i, resorts = locaService.VCData[locaService.regionIndex].destinations[locaService.destIndex].resorts;
        //loop through destination object
        if (resorts) {
            for (i = 0; i < resorts.length; i++) {
                //if the id of resort in loop equals id given
                if (resorts[i].id === resortId) {
                    //set resort name
                    resortName = resorts[i].resort_name;
                    return resortName;
                }
            }
        }
    };

    return findService;
}]);
