resortModule.directive("fixedScroll", ['$window', '$timeout', function ($window, $timeout) {
    return function(scope, element, attrs) {
        angular.element($window).bind("scroll", function() {
            gooeyHeight = $('#header').height();
             if (this.pageYOffset >= gooeyHeight) {
                 scope.$ctrl.fixed = true;
             }
             else if (this.pageYOffset < gooeyHeight) {
                 scope.$ctrl.fixed = false;
             }
            scope.$apply();
        });
    };
}]);
