/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').component('testimonials', {
        templateUrl: 'templates/testimonials/testimonials-component.html',
        controller: TestimonialsController,
    });

    TestimonialsController.$inject = [
        'testimonialService'
    ];

    ////////////////////////////////////////////////////////////////////////////

    function TestimonialsController(testimonialService) {
        var self = this;

        self.numPerPage = 8;
        self.testimonials = [];
        self.pageTemplateUrl = 'testimonials/testimonials-paged.html';

        self.$onInit = onInit;

    ////////////////////////////////////////////////////////////////////////

        function onInit() {
            testimonialService.getTestimonials().then(function (testimonials) {
                self.testimonials = testimonials;
            });
        }
    }
}());
