//FACTORY declaration
resortModule.factory('galleryService', ['locaService', 'fancyboxService', '$rootScope', '$timeout', function (locaService, fancyboxService, $rootScope, $timeout) {

    //variables for factory
    var galleryService = {
        outsideImages: [],
        insideImages: []
    };

    galleryService.filterImages = function (images) {
        var i,
        imagesLength = images.length,
        outsideImages = [],
        insideImages = [];

        for (i = 0; i < imagesLength; i++) {
            var imageType = parseFloat(images[i].resort_image_type_id, 10);
            if (imageType === 1) {
                insideImages.push(images[i]);
                continue;
            }
            outsideImages.push(images[i]);
        }

        return [outsideImages, insideImages];
    };

    galleryService.updateImages = function (info) {
        $timeout( function() {
            images = info.resort_images;
            if (images) {
                var imageArray = galleryService.filterImages(images);
                galleryService.outsideImages = imageArray[0];
                galleryService.insideImages = imageArray[1];
                $rootScope.$broadcast("galleryImagesUpdated");
            }
            return false;
        });
    };

    //on initial load if not loaded in time for broadcast
    galleryService.getNewValue = function (images, resortInfo, outsideImages) {
        if (images && !outsideImages.length) {
            galleryService.updateImages(resortInfo);
        }
        return false;
    };


    //loops through images array and reformats it to fit required lighbox array
    galleryService.openFancyImages = function (inside, index, insideImages, outsideImages) {
        var imageArray;
        //inside images or no, depends on value passed in
        if (inside) {
            imageArray = insideImages;
        } else {
            imageArray = outsideImages;
        }

        fancyboxService.openGalleryImages(index, imageArray);
    };

    galleryService.galleryInit = function (info) {
        fancyboxService.close();
        galleryService.updateImages(info);
    };

    //if images aren't loading
    galleryService.checkLazyImages = function () {
        $timeout(function () {
            var loadedImage = $('.image-gallery .slick-active img');
            var forceLoad = loadedImage.hasClass('slick-loading');
            if (forceLoad) {
                for (i = 0; i < loadedImage.length; i++) {
                    var atrValue = loadedImage[i].getAttribute("data-lazy");
                    loadedImage[i].setAttribute('src', atrValue);
                }
            }
        });
    };

    return galleryService;
}]);
