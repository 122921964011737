resortModule.run(['$rootScope', '$location', '$window', '$route', function ($rootScope, $location, $window, $route) {
    $rootScope
        .$on('$stateChangeStart',
            function () {
                //closes fancyboxes so they don't remain open on the new page
                $.fancybox.close();
                //closes flash message
                $("#feedback").addClass('dismissed');
            }
        );
}]);