/*global angular*/
(function () {
    'use strict';

    angular.module('resortApp').factory('reservationService', ReservationService);

    ReservationService.$inject = ['$http'];

    ////////////////////////////////////////////////////////////////////////////

    function ReservationService($http) {
        var reservationsUrl = '/api/reservations/';

        return {
            getReservation: getReservation
        };

        ////////////////////////////////////////////////////////////////////////

        function getReservation(token) {
            return $http.get(reservationsUrl + token).then(success, error);
        }

        function success(response) {
            return response.data;
        }

        function error(error) {
            throw new Error('ListingService Error: ' + error.statusText);
        }
    }
}());
