//FACTORY AND SERVICE declaration
resortModule.factory('scrollService',  ['$rootScope', function ($rootScope) {

    var scrollService = {};

    scrollService.scrollTop = function () {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    };

    //scrolling script to scroll down page w/o refresh
    //used on both home page and hiw pages
    scrollService.scrollTo = function (eID) {
        // This scrolling function
        var i;
        var startY = currentYPosition();
        // minus 60 for height of gooey
        var stopY = elmYPosition(eID) - 60;
        var distance = stopY > startY ? stopY - startY : startY - stopY;
        if (distance < 100) {
            scrollTo(0, stopY);
            return;
        }
        var speed = Math.round(distance / 100);
        if (speed >= 20) speed = 20;
        var step = Math.round(distance / 25);
        var leapY = stopY > startY ? startY + step : startY - step;
        var timer = 0;
        if (stopY > startY) {
            for (i = startY; i < stopY; i += step) {
                setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
                leapY += step; if (leapY > stopY) leapY = stopY; timer++;
            }

            return;
        }
        for (i = startY; i > stopY; i -= step) {
            setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
            leapY -= step; if (leapY < stopY) leapY = stopY; timer++;
        }

        function currentYPosition() {
            // Firefox, Chrome, Opera, Safari
            if (self.pageYOffset) return self.pageYOffset;
            // Internet Explorer 6 - standards mode
            if (document.documentElement && document.documentElement.scrollTop)
                return document.documentElement.scrollTop;
            // Internet Explorer 6, 7 and 8
            if (document.body.scrollTop) return document.body.scrollTop;
            return 0;
        }

        function elmYPosition(eID) {
            var elm = document.getElementById(eID);
            if (elm) {
                var y = elm.offsetTop;
                var node = elm;
                while (node.offsetParent && node.offsetParent != document.body) {
                    node = node.offsetParent;
                    y += node.offsetTop;
                }
                return y;
            } else {
                return false;
            }
        }

    };

    //returns all of the above info
    return scrollService;
}]);
